import React from 'react';
import { useInView } from 'react-intersection-observer'

export default function DevopsSvg(props) {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.3,
  })

  let className = "freepik-large";
  return (
    <div ref={ref}>
    <svg className={inView ? `${className} animated` : className} id="freepik_stories-processing" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" version="1.1" width={500} height={500} {...props}>
      <g id="freepik--Floor--inject-5" className="animable" style={{transformOrigin: '257.168px 348.469px'}}>
        <path id="freepik--floor--inject-5" d="M105.08,426.24c84,42.94,220.19,42.94,304.18,0s84-112.58,0-155.52-220.18-43-304.18,0S21.08,383.29,105.08,426.24Z" style={{fill: 'rgb(245, 245, 245)', transformOrigin: '257.168px 348.469px'}} className="animable" />
      </g>
      <g id="freepik--Shadows--inject-5" className="animable" style={{transformOrigin: '282.081px 371.555px'}}>
        <path id="freepik--Shadow--inject-5" d="M151.57,425a7.44,7.44,0,0,1-3.66-.91l-32.24-18.61a4.29,4.29,0,0,1,0-7.7l90.84-52.42-17.73-10.24c-2.58-1.49-2.36-3.58-2.23-4.18s.78-2.6,3.75-2.9l97.39-9.93.66,0a6.27,6.27,0,0,1,5.33,2.87h0a3,3,0,0,1,.34,2.49L277,379a4.86,4.86,0,0,1-4.68,3.57,5.21,5.21,0,0,1-2.58-.7l-20.72-12L155.24,424A7.38,7.38,0,0,1,151.57,425Z" style={{fill: 'rgb(224, 224, 224)', transformOrigin: '203.712px 371.555px'}} className="animable" />
        <ellipse id="freepik--shadow--inject-5" cx="403.41" cy="359.47" rx="47.48" ry="25.81" style={{fill: 'rgb(224, 224, 224)', transformOrigin: '403.41px 359.47px'}} className="animable" />
      </g>
      <g id="freepik--Plants--inject-5" className="animable animator-hidden" style={{transformOrigin: '435.502px 329.275px'}}>
        <g id="freepik--plants--inject-5" className="animable" style={{transformOrigin: '435.502px 329.275px'}}>
          <path d="M418,333.29s.42-9.77,4.71-17.64,10.59-13,16.74-13.95,11.54,4.41,5.2,8.78-17.81,9.19-22.19,24.19Z" style={{fill: '#92E3A9', transformOrigin: '432.746px 318.13px'}} id="el5g4gfwto29x" className="animable" />
          <g id="el4tbe23ey6j7">
            <path d="M418,333.29s.42-9.77,4.71-17.64,10.59-13,16.74-13.95,11.54,4.41,5.2,8.78-17.81,9.19-22.19,24.19Z" style={{opacity: '0.15', transformOrigin: '432.746px 318.13px'}} className="animable" id="elccdmfmpz4ie" />
          </g>
          <path d="M419.63,330.65l-.07,0a.33.33,0,0,1-.21-.42c5.39-16.53,17.34-24.11,22.91-25.81a.32.32,0,0,1,.41.22.33.33,0,0,1-.22.41c-5.45,1.67-17.16,9.12-22.47,25.39A.35.35,0,0,1,419.63,330.65Z" style={{fill: 'rgb(255, 255, 255)', transformOrigin: '431.008px 317.527px'}} id="el2s57ehidjrk" className="animable" />
          <path d="M452.83,328.49c-.5,1.39-1.91,2.19-3.24,2.83s-2.76,1.34-3.41,2.66c-1.3,2.67,1.39,6.1,0,8.71a4.62,4.62,0,0,1-3,2c-1,.26-2,.33-3.06.49a14.38,14.38,0,0,1-1.47,0,6.6,6.6,0,0,0-4.21.75c-2,1.29-2.4,3.9-3.5,6a9.71,9.71,0,0,1-6.56,4.79,6.72,6.72,0,0,1-3.56,0l-.21-.11v0a4.64,4.64,0,0,1-.39-.4l0-.06c-.46-1.3,0-2.73,0-4.1a2,2,0,0,0,0-.25c-.17-1.59-.32-3.17-.47-4.76q-.42-4.4-.71-8.79c-.11-1.91-.49-4,.19-5.83.75-2,3-2.23,3.67-4.22.28-.8.4-1.64.67-2.44A6.81,6.81,0,0,1,429,321.3c3.86-.48,7.56,2.42,11.4,1.86a27.52,27.52,0,0,0,2.93-.78c3.09-.8,6.78-.17,8.73,2.36A4,4,0,0,1,452.83,328.49Z" style={{fill: '#92E3A9', transformOrigin: '435.92px 339.103px'}} id="elvfv42bey68" className="animable" />
          <g id="elorlodkxcds">
            <g style={{opacity: '0.07', transformOrigin: '435.92px 339.103px'}} className="animable" id="el7g76uy7wr2h">
              <path d="M452.83,328.49c-.5,1.39-1.91,2.19-3.24,2.83s-2.76,1.34-3.41,2.66c-1.3,2.67,1.39,6.1,0,8.71a4.62,4.62,0,0,1-3,2,16.54,16.54,0,0,1-3.06.49,14.38,14.38,0,0,1-1.47,0,6.6,6.6,0,0,0-4.21.75c-2,1.29-2.4,3.9-3.5,6a9.71,9.71,0,0,1-6.56,4.79,6.72,6.72,0,0,1-3.56,0l-.21-.11v0a.48.48,0,0,1-.16-.12,1,1,0,0,1-.23-.28l0-.06c-.6-1.06,0-2.91,0-4.1a2,2,0,0,0,0-.25c-.17-1.59-.32-3.17-.47-4.76q-.42-4.4-.71-8.79c-.11-1.91-.49-4,.19-5.83.75-2,3-2.23,3.67-4.22.28-.8.4-1.64.67-2.44A6.81,6.81,0,0,1,429,321.3c3.86-.48,7.56,2.42,11.4,1.86a27.52,27.52,0,0,0,2.93-.78c3.09-.8,6.78-.17,8.73,2.36A4,4,0,0,1,452.83,328.49Z" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '435.92px 339.103px'}} id="elfqesc7djo3f" className="animable" />
            </g>
          </g>
          <path d="M419.39,350.07h0a.34.34,0,0,1-.26-.39C423,329.23,444,324.84,449,324.85a.33.33,0,1,1,0,.66c-4.86,0-25.35,4.29-29.2,24.3A.32.32,0,0,1,419.39,350.07Z" style={{fill: 'rgb(255, 255, 255)', transformOrigin: '434.227px 337.467px'}} id="elabp0u21vjr7" className="animable" />
          <path d="M442.17,338.25a.42.42,0,0,1-.18-.08,17.61,17.61,0,0,0-14.68-3,.34.34,0,0,1-.39-.26.33.33,0,0,1,.25-.39,18.31,18.31,0,0,1,15.26,3.19.33.33,0,0,1,0,.47A.36.36,0,0,1,442.17,338.25Z" style={{fill: 'rgb(255, 255, 255)', transformOrigin: '434.721px 336.137px'}} id="elhk3wfvy7wn" className="animable" />
        </g>
      </g>
      <g id="freepik--Clouds--inject-5" className="animable" style={{transformOrigin: '146.99px 87.8439px'}}>
        <g id="freepik--clouds--inject-5" className="animable" style={{transformOrigin: '146.99px 87.8439px'}}>
          <path id="freepik--Cloud--inject-5" d="M153.13,65.1l-8.87,5.12a11.65,11.65,0,0,0,.07-1.17V66.48c0-4.57-3.21-6.43-7.17-4.14a14,14,0,0,0-4.27,4.11c-.65-5.75-5.16-7.89-10.63-4.73-5.93,3.42-10.73,11.74-10.73,18.58v3.84a10.12,10.12,0,0,0,.91,4.45l-7.37,4.25a8.8,8.8,0,0,0-4,6.89c0,2.54,1.78,3.56,4,2.3l48.06-27.75a8.78,8.78,0,0,0,4-6.88C157.1,64.86,155.32,63.83,153.13,65.1Z" style={{fill: 'rgb(235, 235, 235)', transformOrigin: '129.1px 81.4141px'}} className="animable" />
          <path id="freepik--cloud--inject-5" d="M190,87.94l-6.5,3.76a8.43,8.43,0,0,0,.05-.86V89c0-3.35-2.35-4.71-5.25-3a10.18,10.18,0,0,0-3.14,3c-.47-4.21-3.78-5.78-7.79-3.46a17.36,17.36,0,0,0-7.86,13.61v2.82a7.47,7.47,0,0,0,.66,3.26l-5.4,3.12a6.44,6.44,0,0,0-2.91,5c0,1.86,1.3,2.61,2.91,1.68L190,94.68a6.44,6.44,0,0,0,2.91-5.05C192.88,87.77,191.58,87,190,87.94Z" style={{fill: 'rgb(235, 235, 235)', transformOrigin: '172.385px 99.9478px'}} className="animable" />
        </g>
      </g>
      <g id="freepik--Arrow--inject-5" className="animable" style={{transformOrigin: '203.365px 362.329px'}}>
        <path d="M291.73,308.39V315a31.19,31.19,0,0,1-1.08,7.3l-16.11,52.67a3,3,0,0,1-1.1,1.62,2.86,2.86,0,0,1-3.24.09l-21.13-12.2-94.26,54.42a6.66,6.66,0,0,1-3.24.78,6.57,6.57,0,0,1-3.21-.76l-30.1-17.39a7.16,7.16,0,0,1-3.26-5.62V389.3a6.6,6.6,0,0,1,1-3.2,6.69,6.69,0,0,1,2.31-2.43l82.07-47.37-8.94-5.16a7.15,7.15,0,0,1-3.25-5.62V319a4.23,4.23,0,0,1,1.3-3h0a4,4,0,0,1,2.41-1.15L288,305a3.34,3.34,0,0,1,3.17,1.47A3.41,3.41,0,0,1,291.73,308.39Z" style={{fill: '#92E3A9', transformOrigin: '203.365px 362.329px'}} id="el0fmavgwg8j7b" className="animable" />
        <g id="el598ja85ph2u">
          <path d="M151.57,406.65v13a6.57,6.57,0,0,1-3.21-.76l-30.1-17.39a7.16,7.16,0,0,1-3.26-5.62V389.3a6.6,6.6,0,0,1,1-3.2Z" style={{opacity: '0.1', transformOrigin: '133.285px 402.876px'}} className="animable" id="elwy3tvf4ad1" />
        </g>
        <g id="elroefuey8x5j">
          <path d="M212.51,329.27l-12.18,7-8.94-5.16a7.18,7.18,0,0,1-3.25-5.63V319a4.19,4.19,0,0,1,1.3-3h0Z" style={{opacity: '0.1', transformOrigin: '200.325px 326.135px'}} className="animable" id="eliejkxj7kljq" />
        </g>
        <g id="elalqe4g78cpe">
          <path d="M273.44,364.45v12.1a2.84,2.84,0,0,1-3.24.09l-21.13-12.19V350.38Z" style={{opacity: '0.1', transformOrigin: '261.255px 363.742px'}} className="animable" id="elpa6m7nx2n2" />
        </g>
        <g id="elfdzorfnmier">
          <path d="M249.07,350.38v14.07l-94.26,54.41a6.66,6.66,0,0,1-3.24.78v-13Z" style={{opacity: '0.2', transformOrigin: '200.32px 385.01px'}} className="animable" id="elzemniq83zs" />
        </g>
        <g id="elwwj7mn4a8s">
          <path d="M291.73,308.39V315a31.19,31.19,0,0,1-1.08,7.3l-16.11,52.67a3,3,0,0,1-1.1,1.62V364.44l17.72-58A3.41,3.41,0,0,1,291.73,308.39Z" style={{opacity: '0.2', transformOrigin: '282.585px 341.515px'}} className="animable" id="el727q368ucol" />
        </g>
      </g>
      <g id="freepik--Gears--inject-5" className="animable animator-active" style={{transformOrigin: '208.445px 192.402px'}}>
        <path d="M374.89,108.37l-28.58-16.5a46.57,46.57,0,0,0-3.25-14.74A27.64,27.64,0,0,1,359.65,80l.63.37C368.49,85.11,373.88,94.76,374.89,108.37Z" style={{fill: '#92E3A9', transformOrigin: '358.975px 92.6183px'}} id="ellwinf95tm6o" className="animable" />
        <g id="el5lni119wh4s">
          <path d="M374.89,108.37l-28.58-16.5a46.57,46.57,0,0,0-3.25-14.74A27.64,27.64,0,0,1,359.65,80l.63.37C368.49,85.11,373.88,94.76,374.89,108.37Z" style={{opacity: '0.3', transformOrigin: '358.975px 92.6183px'}} className="animable" id="elap63x1tugof" />
        </g>
        <path d="M370.71,112.64h0a1.43,1.43,0,0,0-.95.1l-10.7,4.82a1.27,1.27,0,0,1-1.78-.82c-.42-1.35-7.75-2.91-8.35-4.07L353,102.46Z" style={{fill: 'rgb(69, 90, 100)', transformOrigin: '359.82px 110.073px'}} id="elp6hlga1743k" className="animable" />
        <path d="M353.05,102.49v0a1,1,0,0,0-.93,0l-7.9-18.28a1.63,1.63,0,0,0-.63-2.1l-7.74-4.46a1.61,1.61,0,0,0-2.13.49l-8.89,13.37a3,3,0,0,1-2.33,1.16,21.84,21.84,0,0,0-3.1.14V83l-5.94-3.43h0a1,1,0,0,0-1,.09l-12,6.94A3.27,3.27,0,0,0,299,88.87l-1.2,13.37a4.43,4.43,0,0,1-1.25,2.5,72.57,72.57,0,0,0-6.68,7.07,2.32,2.32,0,0,1-2.36.67l-7.71-2.7a1.89,1.89,0,0,0-2.17.81l-8.84,15.3a1.89,1.89,0,0,0,.39,2.28l6.19,5.34a2.29,2.29,0,0,1,.6,2.38,71,71,0,0,0-2.78,9.31,4.41,4.41,0,0,1-1.54,2.33l-11,7.73a3.25,3.25,0,0,0-1.23,2.37v13.88a1,1,0,0,0,.43.89h0l13.91,8-5.07,10.23a1.61,1.61,0,0,0,.63,2.09l18.92,11a1.61,1.61,0,0,0,2.13-.49l8.6-8.8a1,1,0,0,0,.46.83l17.62,10.17.47-13.8s-3.72-4.81-3.65-5.64l19.21-20.92a1.88,1.88,0,0,0,2.17-.8l8.84-15.3a1.89,1.89,0,0,0-.39-2.28l8.51-27.1a3.2,3.2,0,0,0,1.22-2.36Zm-46.57,58.39c-5.16,3-9.42,3.61-12.52,2.17-3.64-1.69,25.87-52.27,29-49.51,2.51,2.23,3.87,6.37,3.87,12.16C326.79,138.66,317.69,154.4,306.48,160.88Z" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '306.424px 141.434px'}} id="ely7iz8m082uc" className="animable" />
        <path d="M356,91l-2.39,4.83-7.68,8.86-9.05-5.23-12.68-7.33a2.11,2.11,0,0,0,.64-.62l8.89-13.36a1.62,1.62,0,0,1,2.11-.51l7.76,4.48,11.77,6.79A1.62,1.62,0,0,1,356,91Z" style={{fill: 'rgb(69, 90, 100)', transformOrigin: '340.179px 91.067px'}} id="elvj5t0tlnv2k" className="animable" />
        <path d="M272.49,146.68a3.48,3.48,0,0,1-.81.85l-11,7.73a3,3,0,0,0-.76.88l18.47,10.68L293,158.53Z" style={{fill: 'rgb(69, 90, 100)', transformOrigin: '276.46px 156.75px'}} id="el6s3yg749gob" className="animable" />
        <path d="M279.1,109.7a1.88,1.88,0,0,0-1.44.89l-8.84,15.3a1.85,1.85,0,0,0-.2,1.18L288,138.27l12.1-16.37Z" style={{fill: 'rgb(69, 90, 100)', transformOrigin: '284.348px 123.985px'}} id="elthbd5my2u3" className="animable" />
        <path d="M331.13,89.8v4.4l-10.7,6.18-21.2-12.25a3.2,3.2,0,0,1,1.24-1.5l12-6.94a1.24,1.24,0,0,1,.6-.18.75.75,0,0,1,.37.09l5.79,3.33.15.09,8,4.62Z" style={{fill: 'rgb(69, 90, 100)', transformOrigin: '315.18px 89.9449px'}} id="elrr1urquqvx" className="animable" />
        <path d="M371.15,127.43V113.55a.89.89,0,0,0-1.36-.88l-10.7,4.82a1.27,1.27,0,0,1-1.78-.83,24.5,24.5,0,0,0-2.39-5.3,3,3,0,0,1-.16-2.59l7.13-14.38a1.63,1.63,0,0,0-.63-2.1l-7.74-4.47a1.62,1.62,0,0,0-2.13.5l-8.89,13.37a2.93,2.93,0,0,1-2.33,1.15,24.51,24.51,0,0,0-5.78.59,1.27,1.27,0,0,1-1.61-1.13l-1.17-11.68a.9.9,0,0,0-1.45-.74l-12,6.94a3.23,3.23,0,0,0-1.43,2.24l-1.21,13.38a4.43,4.43,0,0,1-1.25,2.5,72.57,72.57,0,0,0-6.68,7.07,2.32,2.32,0,0,1-2.36.67L297.5,120a1.89,1.89,0,0,0-2.17.8l-8.84,15.31a1.89,1.89,0,0,0,.39,2.28l6.19,5.34a2.29,2.29,0,0,1,.6,2.38,71,71,0,0,0-2.78,9.31,4.41,4.41,0,0,1-1.54,2.33l-11,7.73a3.25,3.25,0,0,0-1.23,2.37v13.88a.9.9,0,0,0,1.37.88l10.7-4.82a1.27,1.27,0,0,1,1.78.82,23.9,23.9,0,0,0,2.39,5.31,3,3,0,0,1,.16,2.59l-7.13,14.38A1.61,1.61,0,0,0,287,203l7.74,4.47a1.61,1.61,0,0,0,2.13-.5l8.89-13.36a3,3,0,0,1,2.32-1.16,23.58,23.58,0,0,0,5.79-.59,1.28,1.28,0,0,1,1.61,1.13l1.17,11.68a.9.9,0,0,0,1.45.75l12-6.94a3.23,3.23,0,0,0,1.43-2.25l1.21-13.37a4.43,4.43,0,0,1,1.25-2.5,72.41,72.41,0,0,0,6.67-7.07,2.31,2.31,0,0,1,2.37-.67l7.71,2.7a1.9,1.9,0,0,0,2.17-.81l8.84-15.31a1.88,1.88,0,0,0-.39-2.27l-6.19-5.34a2.29,2.29,0,0,1-.6-2.38,73.85,73.85,0,0,0,2.78-9.32,4.49,4.49,0,0,1,1.54-2.33l11-7.73A3.2,3.2,0,0,0,371.15,127.43Zm-47,43.65c-11.22,6.47-20.31,1.22-20.31-11.73s9.09-28.7,20.31-35.17,20.31-1.23,20.31,11.72S335.36,164.6,324.15,171.08Z" style={{fill: 'rgb(55, 71, 79)', transformOrigin: '324.136px 147.647px'}} id="elnn3f7tss5ms" className="animable" />
        <path d="M334.35,132.88,329,129.8h0l-.05,0h0c-2.37-1.34-5.63-1.14-9.23.94-7.24,4.18-13.12,14.36-13.12,22.73,0,4.17,1.47,7.11,3.83,8.48h0l4.86,2.78,1.16-1.94a13.51,13.51,0,0,0,3.76-1.47c7.25-4.18,13.12-14.36,13.13-22.73a13.76,13.76,0,0,0-.42-3.35Z" style={{fill: '#92E3A9', transformOrigin: '320.475px 146.841px'}} id="elu9687y3soys" className="animable" />
        <g id="elsinsub3xzm8">
          <path d="M306.6,153.45c0,4.18,1.47,7.11,3.83,8.48h0l4.86,2.78,1.16-1.94a13.63,13.63,0,0,0,3.76-1.47A27.28,27.28,0,0,0,330,150.63l-19-11A27.29,27.29,0,0,0,306.6,153.45Z" style={{opacity: '0.1', transformOrigin: '318.3px 152.17px'}} className="animable" id="el485l31pexul" />
        </g>
        <g id="els0pgfcykyp">
          <path d="M334.35,132.88,329,129.8h0l-.06,0h0c-2.37-1.34-5.63-1.14-9.23.94a26.1,26.1,0,0,0-8.75,8.93l19,11a26.23,26.23,0,0,0,3.36-12,13.76,13.76,0,0,0-.42-3.35Z" style={{fill: 'rgb(255, 255, 255)', opacity: '0.4', transformOrigin: '322.655px 139.811px'}} className="animable" id="elzuube8rdd3" />
        </g>
        <path d="M324.8,133.63c-7.25,4.18-13.12,14.36-13.12,22.73s5.87,11.76,13.12,7.57,13.12-14.36,13.12-22.73S332.05,129.44,324.8,133.63Z" style={{fill: '#92E3A9', transformOrigin: '324.8px 148.78px'}} id="eluzfhf42e79e" className="animable" />
        <path d="M327.81,139.64a.18.18,0,0,1,.25,0,.41.41,0,0,1,.1.32v2.12a1.29,1.29,0,0,1-.09.45,4,4,0,0,1-.17.4l-1.57,3.34a1.82,1.82,0,0,1,1.65.4,2.82,2.82,0,0,1,.64,2.14,7.69,7.69,0,0,1-1,4,7.6,7.6,0,0,1-2.83,2.77,4.3,4.3,0,0,1-1.65.62,2.07,2.07,0,0,1-1.22-.2,1.81,1.81,0,0,1-.76-.83,3.06,3.06,0,0,1-.28-1.29.8.8,0,0,1,.09-.36.45.45,0,0,1,.2-.24l1.64-.95c.18-.1.32-.12.41-.05l.32.23a.89.89,0,0,0,.45.14,1.46,1.46,0,0,0,.8-.27,3.24,3.24,0,0,0,1-.91,2,2,0,0,0,.41-1.26c0-.47-.13-.73-.41-.78a1.51,1.51,0,0,0-1,.27l-1.08.63a.18.18,0,0,1-.24,0,.43.43,0,0,1-.11-.32v-1.72a1.2,1.2,0,0,1,.09-.45c.06-.16.13-.3.19-.43l1.37-2.93-3.39,1.95a.18.18,0,0,1-.24,0,.44.44,0,0,1-.11-.32V144a1,1,0,0,1,.11-.44.66.66,0,0,1,.24-.31Z" style={{fill: 'rgb(55, 71, 79)', transformOrigin: '324.76px 147.905px'}} id="ell2exphts9b" className="animable" />
        <path d="M332.3,63.84l-.48-.28c-9.2-5.46-22-4.76-36.14,3.4-28,16.17-50.77,55.55-50.77,87.94s-22.74,71.79-50.78,88a49.59,49.59,0,0,1-18.75,6.69,27.68,27.68,0,0,0,10.8,13.32l.5.3c9.2,5.42,22,4.69,36.07-3.45,28-16.19,50.77-55.58,50.77-88S296.25,100,324.3,83.82a49.46,49.46,0,0,1,18.76-6.69A27.64,27.64,0,0,1,359.65,80Z" style={{fill: '#92E3A9', transformOrigin: '267.515px 163.364px'}} id="el3n2d8u2wkwl" className="animable" />
        <path d="M243.09,189.25l24.7-5.54-.05,0-10.56-6.11-9.8-5.67a1.37,1.37,0,0,0-.69-.2l-8.67-.49a1.41,1.41,0,0,1-1.46-1.41,46.69,46.69,0,0,0,0-8.36,4.16,4.16,0,0,1,1-2.56l13.87-1.13c.59-.72,11.94-11.67,11.94-11.67l-.06,0-20.38-11.81a.83.83,0,0,0-.39-.08c-5.87-3.39-16.16-9.35-16.4-9.5a1.64,1.64,0,0,0-1-.19l-10.11,1a2.82,2.82,0,0,0-2.21,1.54l-5.48,14.7a4.83,4.83,0,0,1-2.21,2.14c-1.07.5-2.14,1.07-3.22,1.69l-10.61-6.14c-.42-.28-1.09-.1-1.64.48l-11.1,11.72a2.93,2.93,0,0,0-.86,2.44l3.09,10.46a3.51,3.51,0,0,1-.57,2.52,84.6,84.6,0,0,0-5.1,8.87,4,4,0,0,1-2.41,1.79l-9.5,2.29a3.12,3.12,0,0,0-2.15,1.79l-4.78,16.09a1,1,0,0,0,.36,1.17c.21.17,10.76,6.24,10.76,6.24,0,1.47,0,2.91.18,4.31a4.12,4.12,0,0,1-.94,2.56l-9.69,11.72a2.64,2.64,0,0,0-.58,2.41l4.6,10.17a.73.73,0,0,0,.29.33l20.41,11.81s0,0,0,0h0a.23.23,0,0,0,.06-.09l.3.18h0l16.41,9.49,0,0c.28-.76,8.61-35.27,9-34.72l5.18,7.91a.93.93,0,0,0,.16.18l9.87,5.73,10.65,6.17v0l-7.7-24C232.4,221.75,247.91,192.5,243.09,189.25Zm-59.8,16.06,37.53-39.61a19.74,19.74,0,0,1,1.55,8.24,22.74,22.74,0,0,1,2.55-1.3q.12,1.31.12,2.76c0,13-9.09,28.7-20.31,35.18-.82.47-1.63.89-2.4,1.25a21.74,21.74,0,0,1-.16-2.94C194.16,213.57,186.55,212.5,183.29,205.31Z" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '212.003px 191.363px'}} id="eldfn2ilvkujs" className="animable" />
        <path d="M181.65,248.59l-1-9.46-24.36-14.06a1.87,1.87,0,0,0,0,1.2l4.6,10.17a.73.73,0,0,0,.29.33h0l.05,0Z" style={{fill: 'rgb(69, 90, 100)', transformOrigin: '168.921px 236.83px'}} id="elnrnoq5onw7k" className="animable" />
        <path d="M184.73,194.58l-23.37-13.44a1.82,1.82,0,0,0-.34.62l-4.78,16.1a1,1,0,0,0,.36,1.17l.17.11,10.53,6.09.06,0h0l9.79,5.67Z" style={{fill: 'rgb(69, 90, 100)', transformOrigin: '170.453px 196.02px'}} id="el0cjpqdgmasyb" className="animable" />
        <path d="M177.73,153l20.44,11.73,13.48-13.52L191.26,139.4h0c-.42-.27-1.08-.09-1.63.49l-11.11,11.72A3.56,3.56,0,0,0,177.73,153Z" style={{fill: 'rgb(69, 90, 100)', transformOrigin: '194.69px 152.004px'}} id="elwljvvhni5ri" className="animable" />
        <path d="M226.1,124.67a1.57,1.57,0,0,0-1-.19l-10.11,1a2.91,2.91,0,0,0-1.69.8l26.58,15.35,6.79-3.93-.19-1.23S226.43,124.88,226.1,124.67Z" style={{fill: 'rgb(69, 90, 100)', transformOrigin: '229.985px 133.047px'}} id="el2jpj8wwinur" className="animable" />
        <path d="M267.79,183.71l-.05,0a1.5,1.5,0,0,0-.63-.16l-8.67-.49a1.41,1.41,0,0,1-1.46-1.41c.12-1.37.19-2.73.2-4.05a40.59,40.59,0,0,0-.19-4.31,4.2,4.2,0,0,1,.95-2.56L267.62,159a2.65,2.65,0,0,0,.59-2.41l-4.6-10.17a.68.68,0,0,0-.28-.32l-.06,0a1.41,1.41,0,0,0-1.48.46L251.45,156a1.83,1.83,0,0,1-2.26.38,16.07,16.07,0,0,0-4.42-2.57,1.65,1.65,0,0,1-1-1.81l3.19-14.47a.9.9,0,0,0-.44-1,1.69,1.69,0,0,0-1-.17l-10.11,1a2.78,2.78,0,0,0-2.21,1.54l-5.48,14.7a4.72,4.72,0,0,1-2.21,2.13,45.63,45.63,0,0,0-6.26,3.63c-.85.57-1.8.61-2.17,0l-5.17-7.9c-.37-.56-1.2-.44-1.87.25l-11.1,11.73a2.92,2.92,0,0,0-.86,2.43l3.09,10.47a3.57,3.57,0,0,1-.56,2.52,84.55,84.55,0,0,0-5.11,8.86,4,4,0,0,1-2.41,1.8l-9.5,2.29a3.15,3.15,0,0,0-2.15,1.78l-4.78,16.1a1.14,1.14,0,0,0,1.18,1.45l8.68.49A1.41,1.41,0,0,1,188,213a45.81,45.81,0,0,0,0,8.36A4.08,4.08,0,0,1,187,224l-9.68,11.73a2.64,2.64,0,0,0-.59,2.41l4.6,10.16a.63.63,0,0,0,.28.32s0,0,0,0h0a.74.74,0,0,0,.36.09h0a1.79,1.79,0,0,0,1.13-.53l10.33-9.45a1.84,1.84,0,0,1,2.26-.38,16.37,16.37,0,0,0,4.42,2.57,1.64,1.64,0,0,1,1,1.81L198,257.18a.89.89,0,0,0,.42,1l0,0a1.57,1.57,0,0,0,1,.17l10.11-1a2.82,2.82,0,0,0,2.21-1.54l5.49-14.7a4.78,4.78,0,0,1,2.21-2.14c1.06-.5,2.14-1.07,3.21-1.69s2-1.24,3.05-1.93c.84-.58,1.8-.62,2.16-.06l5.18,7.91a.68.68,0,0,0,.25.23c.42.26,1.07.08,1.61-.49L246,231.22a2.94,2.94,0,0,0,.85-2.43l-3.09-10.46a3.56,3.56,0,0,1,.57-2.52,82.86,82.86,0,0,0,5.1-8.87,4,4,0,0,1,2.42-1.79l9.49-2.29a3.1,3.1,0,0,0,2.15-1.79L268.3,185A1.07,1.07,0,0,0,267.79,183.71ZM217.1,223l-.56.14-.4.09q-.45.09-.9.15c-.36.06-.7.08-1.05.11l-.64,0h-.31a10.15,10.15,0,0,1-9.52-6.41,17.44,17.44,0,0,1-1.39-5.3,21.74,21.74,0,0,1-.16-2.94c.06-9.17,4.77-20.53,12.54-28.74a34.84,34.84,0,0,1,7.66-6.21,22.74,22.74,0,0,1,2.55-1.3,16.13,16.13,0,0,1,7.17-1.52h.08l.36,0,.37,0a2.6,2.6,0,0,1,.39.05,10.14,10.14,0,0,1,8,6.28c4.29,9.49-.63,26-11,37C225.91,219.08,221.3,222,217.1,223Z" style={{fill: 'rgb(55, 71, 79)', transformOrigin: '222.487px 197.357px'}} id="elpytaaxw0mv" className="animable" />
        <line x1="242.88" y1="134.25" x2="263.32" y2="146.08" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '253.1px 140.165px'}} id="el5vqe75zg1r9" className="animable" />
        <path d="M232.8,182.2l-5.34-3.08h0l0,0h0c-2.37-1.34-5.63-1.14-9.23.94-7.24,4.18-13.12,14.36-13.12,22.73,0,4.18,1.46,7.11,3.83,8.49h0l4.86,2.77,1.16-1.94a13.26,13.26,0,0,0,3.77-1.47c7.25-4.18,13.12-14.36,13.12-22.73a13.84,13.84,0,0,0-.41-3.35Z" style={{fill: '#92E3A9', transformOrigin: '218.955px 196.161px'}} id="elx7ij0kae3g" className="animable" />
        <g id="el75zhapygwub">
          <path d="M205.05,202.77c0,4.18,1.46,7.11,3.83,8.49h0l4.87,2.77,1.16-1.94a13.63,13.63,0,0,0,3.76-1.47A27.25,27.25,0,0,0,228.45,200l-19-11A27.37,27.37,0,0,0,205.05,202.77Z" style={{opacity: '0.1', transformOrigin: '216.75px 201.515px'}} className="animable" id="ellpfnqp1xwx" />
        </g>
        <g id="elscknf3v29fi">
          <path d="M232.8,182.2l-5.34-3.08h0l0,0h0c-2.37-1.34-5.62-1.14-9.22.94a26.21,26.21,0,0,0-8.76,8.93l19,11a26.3,26.3,0,0,0,3.36-12.06,13.82,13.82,0,0,0-.42-3.35Z" style={{fill: 'rgb(255, 255, 255)', opacity: '0.4', transformOrigin: '221.14px 189.131px'}} className="animable" id="elypz6fw3qdk" />
        </g>
        <path d="M223.25,183c-7.25,4.18-13.13,14.36-13.13,22.73s5.88,11.76,13.13,7.58,13.12-14.36,13.12-22.73S230.5,178.76,223.25,183Z" style={{fill: '#92E3A9', transformOrigin: '223.245px 198.142px'}} id="eld24pj8srekd" className="animable" />
        <path d="M226.79,192.89a7.67,7.67,0,0,1-.08,1.17,7.84,7.84,0,0,1-.26,1.2,12.51,12.51,0,0,1-.49,1.3c-.21.45-.46.92-.75,1.43l-1.82,3.17,3.26-1.89a.21.21,0,0,1,.25,0,.44.44,0,0,1,.1.32v2.12a1,1,0,0,1-.1.44.67.67,0,0,1-.25.31l-6.86,4a.21.21,0,0,1-.25,0,.44.44,0,0,1-.1-.32v-1.72a3.4,3.4,0,0,1,.08-.69,2.45,2.45,0,0,1,.3-.71l3.88-6.52a4.54,4.54,0,0,0,.57-1.28,3.85,3.85,0,0,0,.12-.9,3,3,0,0,0,0-.37.92.92,0,0,0-.15-.41.5.5,0,0,0-.33-.24.8.8,0,0,0-.58.15,1.89,1.89,0,0,0-.59.53,3.08,3.08,0,0,0-.35.63,2.53,2.53,0,0,0-.18.6l-.09.42a1,1,0,0,1-.52.76l-1.6.92a.15.15,0,0,1-.2,0,.35.35,0,0,1-.08-.27,8.54,8.54,0,0,1,.23-1.8,9.37,9.37,0,0,1,.67-1.9,8.46,8.46,0,0,1,1.12-1.74,5.63,5.63,0,0,1,1.58-1.33,3.09,3.09,0,0,1,1.51-.49,1.67,1.67,0,0,1,1.1.41,2.52,2.52,0,0,1,.67,1.11A5.52,5.52,0,0,1,226.79,192.89Z" style={{fill: 'rgb(55, 71, 79)', transformOrigin: '223.22px 198.141px'}} id="el4oavybo098" className="animable" />
        <path d="M175.38,249.57A49.79,49.79,0,0,1,172,230.39c0-16-5.53-27.28-14.51-32.67l-.91-.53A27.49,27.49,0,0,0,140,194.36a49.93,49.93,0,0,1,3.4,19.17c0,16.11,5.61,27.45,14.71,32.8l28.11,16.56A27.68,27.68,0,0,1,175.38,249.57Z" style={{fill: '#92E3A9', transformOrigin: '163.11px 228.487px'}} id="el7bcuewci3b" className="animable" />
        <g id="elsnw7iv13yif">
          <path d="M175.38,249.57A49.79,49.79,0,0,1,172,230.39c0-16-5.53-27.28-14.51-32.67l-.91-.53A27.49,27.49,0,0,0,140,194.36a49.93,49.93,0,0,1,3.4,19.17c0,16.11,5.61,27.45,14.71,32.8l28.11,16.56A27.68,27.68,0,0,1,175.38,249.57Z" style={{opacity: '0.3', transformOrigin: '163.11px 228.487px'}} className="animable" id="elg0jrs4imj2b" />
        </g>
        <path d="M167.81,229.79h0a1.36,1.36,0,0,0-.95.09l-10.7,4.82a1.27,1.27,0,0,1-1.78-.82c-.42-1.35-7.75-2.9-8.35-4.06l4.11-10.22Z" style={{fill: 'rgb(69, 90, 100)', transformOrigin: '156.92px 227.213px'}} id="elphy5uly9hc" className="animable" />
        <path d="M150.15,219.64v0a1,1,0,0,0-.93,0l-7.9-18.28a1.62,1.62,0,0,0-.63-2.09L133,194.77a1.62,1.62,0,0,0-2.13.5l-8.89,13.37a3,3,0,0,1-2.33,1.15,21.88,21.88,0,0,0-3.1.15v-9.78l-5.94-3.42h0a1,1,0,0,0-1,.09l-12,6.94A3.25,3.25,0,0,0,96.13,206l-1.2,13.38a4.43,4.43,0,0,1-1.25,2.49A73.73,73.73,0,0,0,87,229a2.3,2.3,0,0,1-2.36.67l-7.71-2.69a1.87,1.87,0,0,0-2.17.8L65.92,243a1.89,1.89,0,0,0,.39,2.28l6.19,5.33a2.29,2.29,0,0,1,.6,2.38,71.14,71.14,0,0,0-2.78,9.32,4.45,4.45,0,0,1-1.54,2.33l-11,7.73a3.23,3.23,0,0,0-1.23,2.36v13.88a1,1,0,0,0,.43.89h0l13.91,8-5.07,10.24a1.61,1.61,0,0,0,.63,2.09l18.92,11a1.62,1.62,0,0,0,2.13-.5l8.6-8.79a1,1,0,0,0,.46.82l17.62,10.17.47-13.8S111,304,111,303.14l19.21-20.92a1.9,1.9,0,0,0,2.17-.8l8.84-15.31a1.89,1.89,0,0,0-.39-2.28l8.51-27.09a3.27,3.27,0,0,0,1.22-2.36ZM103.58,278c-5.16,3-9.42,3.62-12.52,2.18-3.64-1.7,25.87-52.28,29-49.52,2.51,2.24,3.87,6.38,3.87,12.17C123.89,255.8,114.79,271.55,103.58,278Z" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '103.554px 258.547px'}} id="eluqgpa06z6l" className="animable" />
        <path d="M131.64,249.37l-5.34-3.08h0l0,0h0c-2.37-1.34-5.62-1.14-9.22.94-7.25,4.18-13.13,14.36-13.13,22.73,0,4.18,1.47,7.11,3.84,8.49h0l4.86,2.77,1.16-1.94a13.35,13.35,0,0,0,3.76-1.46c7.25-4.19,13.13-14.37,13.13-22.74a14.36,14.36,0,0,0-.41-3.35Z" style={{fill: '#92E3A9', transformOrigin: '117.795px 263.331px'}} id="elk5e63qme5mp" className="animable" />
        <g id="el7h0o3vqwt7o">
          <path d="M103.9,269.94c0,4.18,1.46,7.11,3.82,8.49h0l4.86,2.77,1.16-1.94a13.39,13.39,0,0,0,3.76-1.47,27.25,27.25,0,0,0,9.77-10.67l-19-11A27.27,27.27,0,0,0,103.9,269.94Z" style={{opacity: '0.1', transformOrigin: '115.585px 268.66px'}} className="animable" id="elprc9rahjp3" />
        </g>
        <g id="eld7ndunh220h">
          <path d="M131.64,249.37l-5.34-3.08h0l-.05,0h0c-2.37-1.34-5.62-1.14-9.22.94a26.12,26.12,0,0,0-8.76,8.93l19,11a26.3,26.3,0,0,0,3.36-12.06,14.36,14.36,0,0,0-.41-3.35Z" style={{fill: 'rgb(255, 255, 255)', opacity: '0.4', transformOrigin: '119.955px 256.301px'}} className="animable" id="el1i70dljopkc" />
        </g>
        <path d="M122.09,250.12c-7.25,4.19-13.12,14.36-13.12,22.73s5.87,11.76,13.12,7.58,13.13-14.36,13.13-22.73S129.34,245.94,122.09,250.12Z" style={{fill: '#92E3A9', transformOrigin: '122.095px 265.275px'}} id="elp18kjaycy4" className="animable" />
        <path d="M118.26,264.8a.48.48,0,0,1-.07-.37,1.41,1.41,0,0,1,.14-.44l2.8-4.91c.06-.12.15-.26.25-.43a1,1,0,0,1,.34-.35l1.59-.92a.18.18,0,0,1,.24,0,.41.41,0,0,1,.11.32v12.92a1.06,1.06,0,0,1-.11.44.58.58,0,0,1-.24.3l-1.7,1a.18.18,0,0,1-.25,0,.4.4,0,0,1-.1-.31v-8.74L119.71,266a.54.54,0,0,1-.26.26.18.18,0,0,1-.23-.08Z" style={{fill: 'rgb(55, 71, 79)', transformOrigin: '120.92px 264.872px'}} id="el1vfm5ad6u9m" className="animable" />
        <path d="M153.09,208.12,150.7,213,143,221.81,134,216.58l-12.68-7.33a2.11,2.11,0,0,0,.64-.62l8.89-13.36a1.62,1.62,0,0,1,2.11-.51l7.76,4.48L152.46,206A1.62,1.62,0,0,1,153.09,208.12Z" style={{fill: 'rgb(69, 90, 100)', transformOrigin: '137.29px 208.187px'}} id="elk2svkukodl" className="animable" />
        <path d="M69.59,263.82a3.53,3.53,0,0,1-.81.86l-11,7.73a2.75,2.75,0,0,0-.76.88L75.51,284l14.58-8.29Z" style={{fill: 'rgb(69, 90, 100)', transformOrigin: '73.555px 273.91px'}} id="elvtkoe7zu6ae" className="animable" />
        <path d="M76.2,226.85a1.87,1.87,0,0,0-1.44.88L65.92,243a1.84,1.84,0,0,0-.2,1.18l19.35,11.19,12.1-16.36Z" style={{fill: 'rgb(69, 90, 100)', transformOrigin: '81.4328px 241.11px'}} id="elskfotozbrx9" className="animable" />
        <path d="M128.23,206.94v4.4l-10.7,6.18-21.2-12.25a3.2,3.2,0,0,1,1.24-1.5l12-6.94a1.24,1.24,0,0,1,.6-.18.75.75,0,0,1,.37.09l5.79,3.33.15.09,8,4.62Z" style={{fill: 'rgb(69, 90, 100)', transformOrigin: '112.28px 207.085px'}} id="elcw1czj0h417" className="animable" />
        <path d="M168.25,244.57V230.69a.89.89,0,0,0-1.36-.88l-10.7,4.82a1.27,1.27,0,0,1-1.78-.82A24.37,24.37,0,0,0,152,228.5a3,3,0,0,1-.16-2.59L159,211.53a1.62,1.62,0,0,0-.63-2.09L150.62,205a1.61,1.61,0,0,0-2.13.5l-8.89,13.36a3,3,0,0,1-2.33,1.16,23.51,23.51,0,0,0-5.78.59,1.28,1.28,0,0,1-1.61-1.13l-1.17-11.68a.89.89,0,0,0-1.45-.74l-12,6.94a3.19,3.19,0,0,0-1.43,2.24l-1.21,13.37a4.43,4.43,0,0,1-1.25,2.5,73.73,73.73,0,0,0-6.68,7.07,2.3,2.3,0,0,1-2.36.67l-7.71-2.69a1.87,1.87,0,0,0-2.17.8l-8.84,15.31a1.88,1.88,0,0,0,.39,2.27l6.19,5.34a2.29,2.29,0,0,1,.6,2.38A70.69,70.69,0,0,0,88,272.55a4.45,4.45,0,0,1-1.54,2.33l-11,7.73A3.21,3.21,0,0,0,74.24,285v13.88a.9.9,0,0,0,1.37.89l10.7-4.83a1.27,1.27,0,0,1,1.78.83,24,24,0,0,0,2.39,5.3,3,3,0,0,1,.16,2.6L83.51,318a1.61,1.61,0,0,0,.63,2.09l7.74,4.47a1.62,1.62,0,0,0,2.13-.5l8.89-13.37a3,3,0,0,1,2.32-1.15A24.6,24.6,0,0,0,111,309a1.28,1.28,0,0,1,1.61,1.13l1.17,11.68a.9.9,0,0,0,1.45.74l12-6.94a3.21,3.21,0,0,0,1.43-2.24L129.9,300a4.46,4.46,0,0,1,1.25-2.5,71.17,71.17,0,0,0,6.67-7.06,2.29,2.29,0,0,1,2.37-.67l7.71,2.69a1.9,1.9,0,0,0,2.17-.8l8.84-15.31a1.89,1.89,0,0,0-.39-2.28l-6.19-5.33a2.3,2.3,0,0,1-.6-2.38,74.85,74.85,0,0,0,2.78-9.32,4.41,4.41,0,0,1,1.54-2.33l11-7.73A3.24,3.24,0,0,0,168.25,244.57Zm-47,43.65c-11.22,6.48-20.31,1.23-20.31-11.72s9.09-28.7,20.31-35.18,20.31-1.22,20.31,11.73S132.46,281.75,121.25,288.22Z" style={{fill: 'rgb(55, 71, 79)', transformOrigin: '121.245px 264.778px'}} id="elpes8e76sz1s" className="animable" />
        <path d="M156.55,197.19A27.49,27.49,0,0,0,140,194.36a49.5,49.5,0,0,0-18.77,6.69c-26.64,15.38-48.48,51.7-50.6,83.06L42,267.26c2.11-31.36,24-67.69,50.61-83.07,13.85-8,26.4-8.84,35.56-3.74l1.08.64Z" style={{fill: '#92E3A9', transformOrigin: '99.275px 230.683px'}} id="ele1h56fvcwjf" className="animable" />
      </g>
      <g id="freepik--Character--inject-5" className="animable" style={{transformOrigin: '391.22px 258.156px'}}>
        <g id="el1jbo1iaaha2">
          <path d="M399.84,144h20a0,0,0,0,1,0,0v21.25a15,15,0,0,1-15,15h-5.38a15,15,0,0,1-15-15v-5.93A15.32,15.32,0,0,1,399.84,144Z" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '402.15px 162.125px', transform: 'rotate(180deg)'}} className="animable" id="elzn5egu06pn" />
        </g>
        <path d="M417.52,145.84l-2.59,2.31a1.74,1.74,0,1,1,2.59-2.31Z" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '415.96px 146.654px'}} id="elgqsaqe1527b" className="animable" />
        <path d="M418.18,149.2l-3.4-.73a1.74,1.74,0,0,1,2.07-1.33A1.72,1.72,0,0,1,418.18,149.2Z" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '416.501px 148.15px'}} id="elge7t8nnudpa" className="animable" />
        <path d="M384,352.16a46.37,46.37,0,0,1-6.73,5.86,2.9,2.9,0,0,0-.94,1c-.31.66-.81,2.29,2.2,3.34a11.19,11.19,0,0,0,9.3-.89c2.47-1.55,2.58-4.77,4.91-6.6,1.73-1.36,3.89-1.71,5.29-3.49,1-1.24.72-2.89.21-4.93-.55-2.22-.81-3.91-1.54-3.74l.12,1c-.92,1.46-5.69,1.9-7.81.89A86.06,86.06,0,0,1,384,352.16Z" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '387.397px 352.841px'}} id="el1snhghulg2c" className="animable" />
        <path d="M381.92,354.24h0c.72-.66,1.42-1.33,2.09-2.07a55.37,55.37,0,0,0,3.89-5.73,6.42,6.42,0,0,0,1.09-4.11l-.59-5.48,7.55-.54.88,7.41h0c.18,1.67-1.21,3.16-3.66,5.28s-5.58,7.06-7.81,8.3-3.81,1.19-4.76.56S379.53,356.69,381.92,354.24Z" style={{fill: 'rgb(255, 168, 167)', transformOrigin: '388.406px 347.302px'}} id="elzx4638bj4v8" className="animable" />
        <path d="M412.73,352.84V351.7c-.45,1.33-2.53,7.06-5.67,9.93-2.51,2.29-5.92,4.93-6.79,7.47s4.75,3.54,7.09,3.12c2.72-.48,6.23-2,8.15-4.37,1.34-1.65,2.2-3.61,3.16-4.91s3.17-2.75,3.69-4.26c.29-.83-.05-2.63-.45-4.16s-.77-2.95-1.25-2.82v.87a6.31,6.31,0,0,1-4,1.34C415.53,354,412.72,353.72,412.73,352.84Z" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '411.324px 362.002px'}} id="el31lwlifzvmn" className="animable" />
        <path d="M404.79,363.64c.78-.69,1.56-1.37,2.27-2,2.2-2,3.87-5.41,4.84-7.73a11.13,11.13,0,0,0,.83-4.18V345.2h7.93v7.36h0v.2l0,0c-.38,1.65-4,6-6.09,8.89-1.39,1.92-4,4.36-7.17,4.78C404.38,366.85,402.85,365.91,404.79,363.64Z" style={{fill: 'rgb(255, 168, 167)', transformOrigin: '412.294px 355.864px'}} id="elf5lg7p15lfm" className="animable" />
        <path d="M419.08,229.51c6.16,13.44-.22,60.77-.22,60.77.19,2.18,2.68,6.47,3.61,16.07,1.1,11.34-1.72,39.81-1.72,39.81a8.6,8.6,0,0,1-8,.32s-7.57-42.88-9-54.16c-1.24-9.84-3.62-33-3.62-33l-6.77,32.91s2.59,5.66,3,9.86c.64,6.63,0,35.35,0,35.35s-2.71,2.06-7.91,1.3c0,0-8.8-40-9.5-48-.41-4.76,5-63.21,5-63.21Z" style={{fill: 'rgb(55, 71, 79)', transformOrigin: '400.826px 287.422px'}} id="el29fb3vtboje" className="animable" />
        <path d="M400.12,259.36l-2-11s-8.45-1.75-12.28-5.35a21.87,21.87,0,0,0,10,6.64l2.25,9.6-3.93,29.1Z" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '392.98px 265.68px'}} id="elzix4jfi16zm" className="animable" />
        <path d="M429.11,223.67c-.06-.88-1.71-10.29-2.85-15.55l-11.06,3.39,2,9.68,9.32,7.48C429.4,228.09,429.17,224.55,429.11,223.67Z" style={{fill: 'rgb(255, 168, 167)', transformOrigin: '422.17px 218.395px'}} id="el873cihudrfp" className="animable" />
        <polygon points="375.62 203.83 370.39 213.42 378.52 220.39 385.97 208.58 375.62 203.83" style={{fill: 'rgb(242, 143, 143)', transformOrigin: '378.18px 212.11px'}} id="el2rfap5q4rcl" className="animable" />
        <path d="M395.23,182.47c-5.18.06-10.89,1.94-12.85,6l-8.11,16.72s4.58,6.71,11.85,6.24Z" style={{fill: '#92E3A9', transformOrigin: '384.75px 196.962px'}} id="elu2kmmhpq35" className="animable" />
        <g id="elfu2y1pu23dp">
          <path d="M395.23,182.47c-5.18.06-10.89,1.94-12.85,6l-8.11,16.72s4.58,6.71,11.85,6.24Z" style={{opacity: '0.1', transformOrigin: '384.75px 196.962px'}} className="animable" id="elmptlwgmgn5b" />
        </g>
        <path d="M408.23,182.83h0a15.58,15.58,0,0,1,10.42,8.52,13.48,13.48,0,0,1,.37,10.16l-5.5,15.9s2.58,6.22,5.56,12.1c-13.79,7.7-31,3.65-35.16-2-.17-1.2.06-6.57-.27-12.68-.36-6.52-1.25-13.87-.23-17.9,1.9-7.48,5.47-14.5,12.56-14.5Z" style={{fill: '#92E3A9', transformOrigin: '401.392px 208.095px'}} id="eln2ctk0rukb" className="animable" />
        <path d="M410.28,182.83c4.71.67,11.62,1.62,14,12.8,2.41,11.34,3.11,15.28,3.11,15.28s-3.87,4.5-11.67,4.46l-2.43-8.59Z" style={{fill: '#92E3A9', transformOrigin: '418.835px 199.1px'}} id="elljop3pg36b" className="animable" />
        <g id="eltbq5657m42d">
          <path d="M415.05,213s-1.59-5.05-2.56-9.16a63.61,63.61,0,0,0,1.28,12.86Z" style={{opacity: '0.1', transformOrigin: '413.77px 210.27px'}} className="animable" id="elyzey91umja" />
        </g>
        <path d="M390.33,151.1c-1.87,1.11-4.17,5.8-3.94,15.63.19,8.33,2.89,10.39,4.25,11s4,.23,6.51-.19v5s-3.51,4.08-3.29,6.37,5.07,2.84,8.36.6a21.16,21.16,0,0,0,5.39-5.65V171.75a3.2,3.2,0,0,0,4.53.43c2.81-1.87,2.91-6.48,1.28-8.14s-5.15-1.13-5.81,1.16c0,0-4.05.15-9.45-2.82A15.17,15.17,0,0,1,390.33,151.1Z" style={{fill: 'rgb(255, 168, 167)', transformOrigin: '400.43px 171.018px'}} id="elilmbkabjph" className="animable" />
        <path d="M395.69,164.63a1,1,0,1,0,2.09.1,1,1,0,1,0-2.09-.1Z" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '396.735px 164.68px'}} id="el6yb7yti0nyw" className="animable" />
        <path d="M395.41,171.7l-2.43.77a1.24,1.24,0,0,0,1.58.87A1.33,1.33,0,0,0,395.41,171.7Z" style={{fill: 'rgb(242, 143, 143)', transformOrigin: '394.223px 172.55px'}} id="el7fpq86y6qth" className="animable" />
        <path d="M387.1,161.42l2.12-1.47a1.23,1.23,0,0,0-1.76-.36A1.34,1.34,0,0,0,387.1,161.42Z" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '388.052px 160.392px'}} id="elfclbmdofc6r" className="animable" />
        <path d="M387.59,163.88a1.06,1.06,0,0,0,1,1.13,1.07,1.07,0,0,0,1.1-1,1,1,0,1,0-2.09-.09Z" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '388.639px 163.963px'}} id="elyeiowwrwkd" className="animable" />
        <polygon points="392.53 163.28 392.36 169.58 389.04 168.55 392.53 163.28" style={{fill: 'rgb(242, 143, 143)', transformOrigin: '390.785px 166.43px'}} id="el3619stcrxei" className="animable" />
        <path d="M397.15,177.52c2.68-.35,8.19-1.94,9.05-4.12a5.67,5.67,0,0,1-1.93,2.78c-1.65,1.42-7.12,3-7.12,3Z" style={{fill: 'rgb(242, 143, 143)', transformOrigin: '401.675px 176.29px'}} id="el4lykc9uh1fc" className="animable" />
        <polygon points="353.3 203.25 383.71 211.26 392.69 249.01 363.77 240.61 353.3 203.25" style={{fill: 'rgb(245, 245, 245)', transformOrigin: '372.995px 226.13px'}} id="el87kqntcbj5" className="animable" />
        <path d="M366.56,228.22c-.28.78-1.95,2.92-5.49,2.75l-.44-1.56Z" style={{fill: 'rgb(224, 224, 224)', transformOrigin: '363.595px 229.6px'}} id="elno923luqlsh" className="animable" />
        <path d="M373.36,229.41c.13,1,2.2,2.86,5.7,4,4,1.33,10.08.63,10.08.63l-.55-1.88Z" style={{fill: 'rgb(224, 224, 224)', transformOrigin: '381.25px 231.817px'}} id="eltx26misnpi" className="animable" />
        <path d="M362.2,208.71l-.25-1.16c-.14-.64,1.65-.67,4-.06l4.48,1.16c2.34.61,4.35,1.62,4.49,2.26l.25,1.16Z" style={{fill: 'rgb(224, 224, 224)', transformOrigin: '368.556px 209.56px'}} id="el1lyc7fm2ol5h" className="animable" />
        <path d="M360.09,204.34c-.08-.4.64-.51,1.62-.24l1.77.49s-.53-2.51,3.41-1.63,4.8,3.72,4.8,3.72l1.9.54c1,.3,2,.86,2.12,1.26l.22.72-15.67-4.12Z" style={{fill: 'rgb(55, 71, 79)', transformOrigin: '368.007px 205.986px'}} id="elahhd3cppp8" className="animable" />
        <path d="M362.25,205.59c1.44.34,2,.54,2.25,1.52s.34,2,.34,2l6.94,1.83s-.34-1.43-.44-2,.13-.9,1.23-.6l.09-.45-10.86-2.76Z" style={{fill: 'rgb(55, 71, 79)', transformOrigin: '367.23px 208.035px'}} id="el8fto08l8hvk" className="animable" />
        <path d="M362,208.82l-.25-1.16c-.14-.64,1.65-.67,4-.06l4.48,1.16c2.34.61,4.35,1.62,4.49,2.26l.25,1.16Z" style={{fill: 'rgb(55, 71, 79)', transformOrigin: '368.356px 209.67px'}} id="ellrrl1d5xvjp" className="animable" />
        <path d="M426.49,228.67l-9.32-7.48s-20.6,3.67-23,3.86a6.35,6.35,0,0,1-6-3.9c-.92-2.37-2.54-2.08-2.54-2.08l.94,4c.23,1-5.57,1.75-8.79.66s-7.87.49-5,4.87,8.5,4.89,12.39,4.89a40.2,40.2,0,0,0,4.88-.33c1.26-.16,2.51-.29,3.78-.38C401.76,232.19,422.86,229.81,426.49,228.67Z" style={{fill: 'rgb(255, 168, 167)', transformOrigin: '399.186px 226.274px'}} id="eltj4koztkhcs" className="animable" />
        <path d="M356.39,214.28l1.35,4.82c.26.92,3.8,2.61,6,3.22s4.69,1.34,3.63,4.47c-1,2.88-4.66,3.54-6.53,3.46s-4.44-1.87-5.45-4.76c-1.29-3.68-.59-7.61-1.1-9.76S355.27,213,356.39,214.28Z" style={{fill: 'rgb(255, 168, 167)', transformOrigin: '360.904px 221.932px'}} id="el2xc6ujjvl2t" className="animable" />
      </g>
      <defs>
        <filter id="active" height="200%">
          <feMorphology in="SourceAlpha" result="DILATED" operator="dilate" radius={2} />
          <feFlood floodColor="#32DFEC" floodOpacity={1} result="PINK" />
          <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
          <feMerge>
            <feMergeNode in="OUTLINE" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="hover" height="200%">
          <feMorphology in="SourceAlpha" result="DILATED" operator="dilate" radius={2} />
          <feFlood floodColor="#ff0000" floodOpacity="0.5" result="PINK" />
          <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
          <feMerge>
            <feMergeNode in="OUTLINE" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
          <feColorMatrix type="matrix" values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 " />
        </filter>
      </defs>
    </svg>
    </div>
  );
}
