import React from 'react';

import Layout from '../components/Layout';

import Scroll from '../components/Scroll';

import config from '../../config';
import CodingSvg from '../components/svgs/CodingSvg';
import CloudSvg from '../components/svgs/CloudSvg';
import DevopsSvg from '../components/svgs/DevopsSvg';
import WebSvg from '../components/svgs/WebSvg';
import { SEO } from '../components/SEO';
import { Tags } from '../components/Text';

const menuLinks = {
  "Home": "/",
  "Tech Blogs": "/blogs"
}

const IndexPage = () => (
  <Layout menuLinks={menuLinks}>
    <SEO title={config.siteTitle} description={config.siteDescription} />
    <section id="banner">
      <div className="inner">
        <span id="favicon-main" />
        <h2>{config.heading}</h2>
        <p>{config.subHeading}</p>
        <ul className="actions special">
          <li>
            <Scroll type="id" element="one">
              <a href="/#" className="button primary">
                Explore
              </a>
            </Scroll>
          </li>
        </ul>
      </div>
      <Scroll type="id" element="one">
        <a href="#one" className="more">
          Learn More
        </a>
      </Scroll>
    </section>

    <section id="one" className="wrapper style3 special">
      <div className="inner">
        <header className="major">
          <h2>About Me</h2>
          <p>
            I have been entranced by computers from ever since I can remember. One day when I was very young, my dad got
            home the best piece of technology ever built, with a 486dx2 processor that ran DOS. Quarter of a century
            later and I'm still hooked on, enough to make a career out of my hobby to code.
          </p>
          <p>
            I started my journey as a developer by writing simple programs and games using GW-BASIC, and now I build
            software applications and Cloud micro-services using high-level programming languages like Java and Scala.
          </p>
        </header>
        <ul className="icons major">
          <li>
            <span className="icon brands fa-java major style3">
              <span className="label">Lorem</span>
            </span>
          </li>
          <li>
            <span className="icon devicon devicon-scala-plain major style2">
              <span className="label">Ipsum</span>
            </span>
          </li>
          <li>
            <span className="icon brands fa-python major style1">
              <span className="label">Dolor</span>
            </span>
          </li>
        </ul>
      </div>
    </section>

    <section id="two" className="wrapper alt style4">
      <section className="spotlight">
        <div className="image">
          <CodingSvg />
          {/*<img src={codePic} alt="" />*/}
        </div>
        <div className="content">
          <h2>Backend Development</h2>
          <p>
            Most of my experience is in building backend applications using high-level programming languages like Java
            or Scala. I have primarily developed web services using frameworks like Finatra and am familiar with build
            tools like SBT or Gradle.
          </p>
          <Tags
            backgroundColor={Tags.BackgroundColor.RED}
            labels={['Java', 'Scala', 'Python', 'SQL', 'Postgres', 'SBT', 'Gradle', 'Spring Boot', 'Finatra']}
          />
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <CloudSvg />
          {/*<img src={cloudPic} alt="" />*/}
        </div>
        <div className="content">
          <h2>Industry Knowledge</h2>
          <p>
            I have worked on building micro-services in the SaaS layer and have extensive experience in that domain. I
            have worked on VMware Cloud on AWS (VMC) and am familiar with the various products and services offered by
            Amazon Web Services, like EC2, S3, EBS etc. I also have some knowledge of VMware's products like vSphere,
            NSX and vSAN.
          </p>
          <Tags
            backgroundColor={Tags.BackgroundColor.BLUE}
            labels={['SaaS', 'Micro-Services', 'VMC', 'AWS', 'VMware vSphere']}
          />
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <DevopsSvg />
          {/*<img src={devopsPic} alt="" />*/}
        </div>
        <div className="content">
          <h2>DevOps and Tools</h2>
          <p>
            While developing software in the SaaS world, I have been responsible for both development and operations. I
            am very familiar with building seamless CI/CD pipelines to deploy micro-services using Jenkins CI, Ansible,
            Docker and Kubernetes. I have primarily worked on Linux environments and am comfortable with scripting
            languages like Bash or Python. I have primarily been in teams using the Agile model of development, but have
            also used the Waterfall model in the past.
          </p>
          <Tags
            backgroundColor={Tags.BackgroundColor.GREEN}
            labels={['Agile Methodologies', 'Docker', 'Kubernetes', 'CI/CD', 'Jenkins', 'Ansible', 'Shell Scripting']}
          />
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <WebSvg />
          {/*<img src={webPic} alt="" />*/}
        </div>
        <div className="content">
          <h2>Front-end Development</h2>
          <p>
            I have recently started exploring some aspects of front-end development and working with the JAMstack. This
            website is built using Gatsby, a static site generator based on React. I've also used UI component libraries
            like Semantic UI and Ant Design.
          </p>
          <Tags
            backgroundColor={Tags.BackgroundColor.PURPLE}
            labels={['HTML', 'CSS', 'Javascript', 'React', 'Gatsby', 'JAMStack', 'Semantic UI', 'Ant Design']}
          />
        </div>
      </section>
    </section>

    {/*<section id="three" className="wrapper style3 special">*/}
    {/*  <div className="inner">*/}
    {/*    <header className="major">*/}
    {/*      <h2>Accumsan mus tortor nunc aliquet</h2>*/}
    {/*      <p>*/}
    {/*        Aliquam ut ex ut augue consectetur interdum. Donec amet imperdiet eleifend*/}
    {/*        <br />*/}
    {/*        fringilla tincidunt. Nullam dui leo Aenean mi ligula, rhoncus ullamcorper.*/}
    {/*      </p>*/}
    {/*    </header>*/}
    {/*    <ul className="features">*/}
    {/*      <li className="icon fa-paper-plane">*/}
    {/*        <h3>Arcu accumsan</h3>*/}
    {/*        <p>*/}
    {/*          Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem tincidunt nullam amet leo Aenean ligula*/}
    {/*          consequat consequat.*/}
    {/*        </p>*/}
    {/*      </li>*/}
    {/*      <li className="icon solid fa-laptop">*/}
    {/*        <h3>Ac Augue Eget</h3>*/}
    {/*        <p>*/}
    {/*          Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem tincidunt nullam amet leo Aenean ligula*/}
    {/*          consequat consequat.*/}
    {/*        </p>*/}
    {/*      </li>*/}
    {/*      <li className="icon solid fa-code">*/}
    {/*        <h3>Mus Scelerisque</h3>*/}
    {/*        <p>*/}
    {/*          Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem tincidunt nullam amet leo Aenean ligula*/}
    {/*          consequat consequat.*/}
    {/*        </p>*/}
    {/*      </li>*/}
    {/*      <li className="icon solid fa-headphones-alt">*/}
    {/*        <h3>Mauris Imperdiet</h3>*/}
    {/*        <p>*/}
    {/*          Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem tincidunt nullam amet leo Aenean ligula*/}
    {/*          consequat consequat.*/}
    {/*        </p>*/}
    {/*      </li>*/}
    {/*      <li className="icon fa-heart">*/}
    {/*        <h3>Aenean Primis</h3>*/}
    {/*        <p>*/}
    {/*          Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem tincidunt nullam amet leo Aenean ligula*/}
    {/*          consequat consequat.*/}
    {/*        </p>*/}
    {/*      </li>*/}
    {/*      <li className="icon fa-flag">*/}
    {/*        <h3>Tortor Ut</h3>*/}
    {/*        <p>*/}
    {/*          Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem tincidunt nullam amet leo Aenean ligula*/}
    {/*          consequat consequat.*/}
    {/*        </p>*/}
    {/*      </li>*/}
    {/*    </ul>*/}
    {/*  </div>*/}
    {/*</section>*/}

    {/*<section id="cta" className="wrapper style4">*/}
    {/*  <div className="inner">*/}
    {/*    <header>*/}
    {/*      <h2>Arcue ut vel commodo</h2>*/}
    {/*      <p>Aliquam ut ex ut augue consectetur interdum endrerit imperdiet amet eleifend fringilla.</p>*/}
    {/*    </header>*/}
    {/*    <ul className="actions stacked">*/}
    {/*      <li>*/}
    {/*        <a href="/#" className="button fit primary">*/}
    {/*          Activate*/}
    {/*        </a>*/}
    {/*      </li>*/}
    {/*      <li>*/}
    {/*        <a href="/#" className="button fit">*/}
    {/*          Learn More*/}
    {/*        </a>*/}
    {/*      </li>*/}
    {/*    </ul>*/}
    {/*  </div>*/}
    {/*</section>*/}
  </Layout>
);

export default IndexPage;
