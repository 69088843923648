import React from 'react';
import { useInView } from 'react-intersection-observer'

export default function CloudSvg(props) {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.3,
  })

  let className = "freepik";
  return (
    <div ref={ref}>
    <svg className={inView ? `${className} animated` : className} id="freepik_stories-cloud-sync" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" version="1.1" width={500} height={500} {...props}>
      <g id="freepik--background-simple--inject-2" className="animable" style={{transformOrigin: '263.951px 217.335px'}}>
        <path d="M436.24,211.17c-11.4-14.33-12.85-33.36-14.28-50.9C420.65,144,413.82,125.2,402,113.16c-8.84-9-20.81-13.57-33.33-14.12-18.91-.84-37.24,5.38-55.66,8.58-39,6.77-41-32.1-122.87-28S90.69,132.74,86.79,166.18s32.18,47.37,15.6,73-34.13,30.79-27.3,63.44,29.25,51.48,79,52.71,78-45.72,107.27-47.87S306.2,355.81,373.49,347c30.68-4,59.93-21.53,73.43-50.09,7.9-16.7,9.33-36.1,5-54a80.56,80.56,0,0,0-10.21-24.39A91.32,91.32,0,0,0,436.24,211.17Z" style={{fill: 'rgb(144, 202, 249)', transformOrigin: '263.951px 217.335px'}} id="elpgp4uu6c3ph" className="animable" />
        <g id="eli89ooh2sb5">
          <path d="M436.24,211.17c-11.4-14.33-12.85-33.36-14.28-50.9C420.65,144,413.82,125.2,402,113.16c-8.84-9-20.81-13.57-33.33-14.12-18.91-.84-37.24,5.38-55.66,8.58-39,6.77-41-32.1-122.87-28S90.69,132.74,86.79,166.18s32.18,47.37,15.6,73-34.13,30.79-27.3,63.44,29.25,51.48,79,52.71,78-45.72,107.27-47.87S306.2,355.81,373.49,347c30.68-4,59.93-21.53,73.43-50.09,7.9-16.7,9.33-36.1,5-54a80.56,80.56,0,0,0-10.21-24.39A91.32,91.32,0,0,0,436.24,211.17Z" style={{fill: 'rgb(255, 255, 255)', opacity: '0.7', transformOrigin: '263.951px 217.335px'}} className="animable" />
        </g>
      </g>
      <g id="freepik--Server--inject-2" className="animable animator-active" style={{transformOrigin: '257.115px 166.032px'}}>
        <path d="M429,249.89a66.47,66.47,0,0,0-35.43-54.63,66.45,66.45,0,0,0-109.81-72.13,66.47,66.47,0,0,0-125.87,41.61c-2-.19-4.11-.3-6.2-.3A66.46,66.46,0,0,0,88,249.89Z" style={{fill: 'rgb(144, 202, 249)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', transformOrigin: '257.115px 166.032px'}} id="eld17ej67y8x" className="animable" />
        <path d="M88,249.89H429c-.14-2.17-.38-4.32-.72-6.42H86.43A66,66,0,0,0,88,249.89Z" style={{fill: 'rgb(38, 50, 56)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '257.715px 246.68px'}} id="elguhe1osoa2k" className="animable" />
        <path d="M196.9,141.82l2.41-1.1-.51-2.89-2.66-.21a11.06,11.06,0,0,0-1.34-2.52l1.38-2.32-2.08-2.08-2.34,1.39a11.47,11.47,0,0,0-4.46-1.82l-.67-2.67h-2.94l-.68,2.68a11.87,11.87,0,0,0-2.65.84l-2.1-1.82L175.85,131l1,2.6a12.42,12.42,0,0,0-1.69,2.19l-2.76-.28-1,2.76,2.29,1.56a12.41,12.41,0,0,0-.16,2c0,.28,0,.55,0,.83l-2.41,1.34.76,2.84,2.73,0a11.25,11.25,0,0,0,1.5,2.38l-1.18,2.45,2.25,1.89,2.19-1.56A11.54,11.54,0,0,0,182,153l.43,2.63,2.93.26.89-2.49a12,12,0,0,0,2.82-.61l1.85,1.87,2.54-1.47-.7-2.53a11.64,11.64,0,0,0,2-2.13l2.59.47,1.24-2.66-2-1.68A11.67,11.67,0,0,0,196.9,141.82Zm-4.35,0a7.35,7.35,0,1,1-7.35-7.34A7.34,7.34,0,0,1,192.55,141.77Z" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '185.215px 141.745px'}} id="elj5xgf04vyrd" className="animable" />
        <polyline points="86.87 219.38 140.19 219.38 148.79 227.98 423.58 227.98" style={{fill: 'none', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', transformOrigin: '255.225px 223.68px'}} id="elsk1ejz4zir9" className="animable" />
        <polyline points="282.96 243.9 282.96 138.97 345.32 138.97 345.32 102.84" style={{fill: 'none', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', transformOrigin: '314.14px 173.37px'}} id="el61357e4626a" className="animable" />
        <path d="M248.8,116.51l1.48-.67-.31-1.77-1.63-.13a7.3,7.3,0,0,0-.81-1.54l.83-1.42-1.27-1.27-1.42.85a7.13,7.13,0,0,0-2.73-1.11l-.41-1.63h-1.8l-.42,1.64a7.37,7.37,0,0,0-1.61.51l-1.29-1.11-1.47,1,.61,1.59a6.78,6.78,0,0,0-1,1.34l-1.69-.17-.61,1.69,1.4,1a6.59,6.59,0,0,0-.1,1.19,4.1,4.1,0,0,0,0,.5l-1.48.83.47,1.73,1.67,0a6.63,6.63,0,0,0,.91,1.46l-.72,1.5,1.38,1.15,1.33-.95a7.14,7.14,0,0,0,1.61.68L240,125l1.79.15.54-1.52a6.93,6.93,0,0,0,1.72-.37l1.13,1.14,1.56-.89-.43-1.55a7.1,7.1,0,0,0,1.2-1.31l1.58.29.76-1.63-1.24-1A7.23,7.23,0,0,0,248.8,116.51Zm-2.66,0a4.49,4.49,0,1,1-4.48-4.49A4.48,4.48,0,0,1,246.14,116.48Z" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '241.675px 116.485px'}} id="elad1zfpz5dn" className="animable" />
        <path d="M271.57,136.79l1.48-.67-.31-1.77-1.63-.13a6.69,6.69,0,0,0-.82-1.54l.84-1.42L269.86,130l-1.43.85a7,7,0,0,0-2.72-1.12l-.41-1.63h-1.8l-.42,1.64a7.39,7.39,0,0,0-1.61.52l-1.29-1.11-1.47,1,.61,1.59a6.78,6.78,0,0,0-1,1.34l-1.69-.18-.61,1.69,1.4.95a6.78,6.78,0,0,0-.1,1.2,4.18,4.18,0,0,0,0,.5l-1.48.83.47,1.73,1.67,0a7.13,7.13,0,0,0,.91,1.46l-.72,1.49,1.38,1.16,1.33-1a7,7,0,0,0,1.61.68l.27,1.61,1.79.16.54-1.52a7.42,7.42,0,0,0,1.72-.38l1.13,1.15,1.56-.9-.43-1.55a7,7,0,0,0,1.2-1.3l1.58.29.76-1.63-1.24-1A7.23,7.23,0,0,0,271.57,136.79Zm-2.66,0a4.49,4.49,0,1,1-4.49-4.49A4.49,4.49,0,0,1,268.91,136.76Z" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '264.445px 136.73px'}} id="el7fuf6o30qnb" className="animable" />
        <circle cx="119.12" cy="209.06" r="6.02" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '119.12px 209.06px'}} id="el1amhudnjkjj" className="animable" />
        <circle cx="119.12" cy="209.06" r="4.73" style={{fill: 'rgb(144, 202, 249)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', transformOrigin: '119.12px 209.06px'}} id="el32o77cdb6nw" className="animable" />
        <rect x="179.32" y="194.73" width="48.16" height="23.65" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '203.4px 206.555px'}} id="elkjxhhl1bsw" className="animable" />
        <rect x="162.12" y="145.71" width="48.16" height="98.05" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '186.2px 194.735px'}} id="el960gcdxy1n4" className="animable" />
        <polygon points="335 234.72 223.29 234.72 234.47 169.07 323.82 169.07 335 234.72" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '279.145px 201.895px'}} id="el1wb2f55xz0b" className="animable" />
        <rect x="237.81" y="172.37" width="11.18" height="8.17" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '243.4px 176.455px'}} id="elnskc8t3jr" className="animable" />
        <rect x="252.43" y="172.37" width="11.18" height="8.17" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '258.02px 176.455px'}} id="elg04rbczmhrq" className="animable" />
        <rect x="288.12" y="172.37" width="27.52" height="8.17" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '301.88px 176.455px'}} id="el53vms0f3ygb" className="animable" />
        <rect x="214.26" y="178.11" width="129.76" height="65.65" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '279.14px 210.935px'}} id="el5ivyt5n9qyq" className="animable" />
        <rect x="262.75" y="183.98" width="1.72" height="17.63" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '263.61px 192.795px'}} id="el2ccxq4pjsgp" className="animable" />
        <rect x="268.77" y="183.98" width="1.72" height="17.63" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '269.63px 192.795px'}} id="el4h09zb9os3e" className="animable" />
        <rect x="274.79" y="183.98" width="1.72" height="17.63" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '275.65px 192.795px'}} id="elhm95zwd9rur" className="animable" />
        <rect x="322.95" y="183.98" width="1.72" height="17.63" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '323.81px 192.795px'}} id="el9e50oyfle1" className="animable" />
        <rect x="328.98" y="183.98" width="1.72" height="17.63" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '329.84px 192.795px'}} id="elgsrag5y68h" className="animable" />
        <rect x={335} y="183.98" width="1.72" height="17.63" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '335.86px 192.795px'}} id="elvtpnotm1e" className="animable" />
        <rect x="214.26" y="199.03" width="129.76" height="15.05" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '279.14px 206.555px'}} id="eleqqrsuqqm8" className="animable" />
        <rect x="264.47" y="201.61" width="3.87" height="3.87" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '266.405px 203.545px'}} id="el72578fwh8lf" className="animable" />
        <rect x="272.86" y="201.61" width="3.87" height="3.87" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '274.795px 203.545px'}} id="elxbz1dmtgi7" className="animable" />
        <rect x="281.24" y="201.61" width="3.87" height="3.87" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '283.175px 203.545px'}} id="ele2a6le89wel" className="animable" />
        <rect x="264.47" y="207.85" width="3.87" height="3.87" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '266.405px 209.785px'}} id="elzysoc0gvtaf" className="animable" />
        <rect x="272.86" y="207.85" width="3.87" height="3.87" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '274.795px 209.785px'}} id="el1fypgyiff2g" className="animable" />
        <rect x="281.24" y="207.85" width="3.87" height="3.87" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '283.175px 209.785px'}} id="el15jkbn7d9hs" className="animable" />
        <rect x="185.13" y="215.59" width="3.87" height="3.87" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '187.065px 217.525px'}} id="el8qwjjub19ih" className="animable" />
        <rect x="193.51" y="215.59" width="3.87" height="3.87" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '195.445px 217.525px'}} id="elr96m0mtvrk" className="animable" />
        <rect x="201.9" y="215.59" width="3.87" height="3.87" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '203.835px 217.525px'}} id="eld5yt4ynsj2" className="animable" />
        <rect x="319.73" y="207.85" width="3.87" height="3.87" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '321.665px 209.785px'}} id="elmjiw2q1ocmb" className="animable" />
        <rect x="328.11" y="207.85" width="3.87" height="3.87" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '330.045px 209.785px'}} id="elxqf2d407qp" className="animable" />
        <rect x="336.5" y="207.85" width="3.87" height="3.87" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '338.435px 209.785px'}} id="eli68oa819zk" className="animable" />
        <rect x="214.26" y="214.08" width="129.76" height="6.02" style={{fill: 'rgb(38, 50, 56)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '279.14px 217.09px'}} id="el56axdsfhkv" className="animable" />
        <path d="M206.85,175.81a2.15,2.15,0,1,1-2.15-2.15A2.15,2.15,0,0,1,206.85,175.81Z" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '204.7px 175.81px'}} id="elmwtj2h3xrj" className="animable" />
        <path d="M191.23,151.81a2.15,2.15,0,1,1-2.15-2.15A2.15,2.15,0,0,1,191.23,151.81Z" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '189.08px 151.81px'}} id="el7bd3i76ia2v" className="animable" />
        <path d="M206.85,151.81a2.15,2.15,0,1,1-2.15-2.15A2.16,2.16,0,0,1,206.85,151.81Z" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '204.7px 151.81px'}} id="elfwlqklyk2in" className="animable" />
        <path d="M206.85,187.85a2.15,2.15,0,1,1-2.15-2.15A2.15,2.15,0,0,1,206.85,187.85Z" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '204.7px 187.85px'}} id="el8r453x0qxnk" className="animable" />
        <path d="M206.85,199.89a2.15,2.15,0,1,1-2.15-2.15A2.15,2.15,0,0,1,206.85,199.89Z" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '204.7px 199.89px'}} id="el9f2h8josazp" className="animable" />
        <path d="M230.93,187.85a6,6,0,1,0-6,6A6,6,0,0,0,230.93,187.85Z" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '224.93px 187.85px'}} id="elx08psz0w5k" className="animable" />
        <path d="M229.64,187.85a4.73,4.73,0,1,0-4.73,4.73A4.73,4.73,0,0,0,229.64,187.85Z" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '224.91px 187.85px'}} id="elcy2enjoj5ft" className="animable" />
        <path d="M197.38,198.17a6,6,0,1,0-6,6A6,6,0,0,0,197.38,198.17Z" style={{fill: 'rgb(38, 50, 56)', transformOrigin: '191.38px 198.17px'}} id="elk9ijkzuhg6f" className="animable" />
        <path d="M196.09,198.17a4.73,4.73,0,1,0-4.73,4.73A4.73,4.73,0,0,0,196.09,198.17Z" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '191.36px 198.17px'}} id="eltru4rc49nv" className="animable" />
        <polyline points="209 211.5 173.73 211.5 173.73 241.6" style={{fill: 'none', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '191.365px 226.55px'}} id="elinwqpdccp9" className="animable" />
        <polyline points="207.71 157.75 185.77 157.75 176.96 148.93 164.27 148.93" style={{fill: 'none', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '185.99px 153.34px'}} id="el9hylgxcm4o" className="animable" />
        <line x1="282.1" y1="185.27" x2="290.27" y2="185.27" style={{fill: 'none', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '286.185px 185.27px'}} id="elemp8mplwcr9" className="animable" />
        <line x1="282.1" y1="188.71" x2="290.27" y2="188.71" style={{fill: 'none', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '286.185px 188.71px'}} id="elnhl9j7nyj4f" className="animable" />
        <line x1="282.1" y1="192.15" x2="290.27" y2="192.15" style={{fill: 'none', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '286.185px 192.15px'}} id="elmj106tj5hf" className="animable" />
      </g>
      <g id="freepik--charater-4--inject-2" className="animable" style={{transformOrigin: '364.125px 200.504px'}}>
        <path d="M366.67,156.15a3.86,3.86,0,0,0-4.26,1.73c-1.58,2.52.16,8.67.79,10.25s3.15.63,3.15.63l.79,2,2.21-.31v-2.84a16.53,16.53,0,0,0,1.26-7.25C370.29,156.62,367.61,156.15,366.67,156.15Z" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '366.199px 163.394px'}} id="elh9fb94ifma7" className="animable" />
        <polygon points="366.37 183.98 355.07 184.29 367.76 185.84 366.37 183.98" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '361.415px 184.91px'}} id="el7s9n275x7zj" className="animable" />
        <path d="M362.57,210.05s-.51,11.6-.83,16.17-.47,15.13-.47,15.13h1.26l2.52-19.54,1.46-11.92Z" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '363.89px 225.62px'}} id="el7nj7vd829yk" className="animable" />
        <polygon points="367.77 209.89 370.1 227.17 376.71 241.35 377.98 241.35 372.77 226.38 372.34 210.05 367.77 209.89" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '372.875px 225.62px'}} id="el5e03qgb17kl" className="animable" />
        <polygon points="361.27 241.35 357.96 244.98 362.53 243.25 362.53 241.35 361.27 241.35" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '360.245px 243.165px'}} id="elc5akuyd1kd5" className="animable" />
        <polygon points="376.71 241.35 373.4 244.98 377.98 243.25 377.98 241.35 376.71 241.35" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '375.69px 243.165px'}} id="elgo4am77t1uh" className="animable" />
        <path d="M367.14,170.8s-3.63,7.25-3.63,8.2,2.68,6.15,2.68,6.15l-4.74,32.61h12.92l-1.08-17.17a24.76,24.76,0,0,0-.48-9.3c-1.26-4.09-2.36-6.93-2.36-6.93l1.57-6.94-2.67-6.93Z" style={{fill: 'rgb(38, 50, 56)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '367.91px 194.125px'}} id="ellsqmf80jb6t" className="animable" />
        <path d="M370.61,160.4c-.32-3.78-3-4.25-3.94-4.25a3.86,3.86,0,0,0-4.26,1.73,4,4,0,0,0-.42,1,2.32,2.32,0,0,1,3.1.25c1.73,1.73,0,2.84.79,3.78s2.68.16,2.36,1.74a2.47,2.47,0,0,0,1.13,2.93A16.42,16.42,0,0,0,370.61,160.4Z" style={{fill: 'rgb(38, 50, 56)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '366.311px 161.804px'}} id="elgfr4i3iq0r" className="animable" />
        <path d="M376.44,161a3,3,0,1,1-3-3A3,3,0,0,1,376.44,161Z" style={{fill: 'rgb(38, 50, 56)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '373.44px 161px'}} id="el0hz222kgu9vl" className="animable" />
        <polygon points="350.27 180.27 357.08 191.72 358.17 191.1 351.36 179.5 350.27 180.27" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '354.22px 185.61px'}} id="el9cojs2g7kbl" className="animable" />
        <path d="M369.66,174.59a1.48,1.48,0,0,0-2.05,1.26c-.31,1.89-1.57,10.4-1.57,10.4H353.27l1.26,1.1,13.55,1.58,2.37-11A2.34,2.34,0,0,0,369.66,174.59Z" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '362.058px 181.702px'}} id="el3o4i02metot" className="animable" />
      </g>
      <g id="freepik--character-3--inject-2" className="animable" style={{transformOrigin: '309.01px 215.315px'}}>
        <rect x="307.25" y="191.91" width="3.49" height="5.97" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '308.995px 194.895px'}} id="el5e2eklbzpd8" className="animable" />
        <rect x="304.57" y="189.19" width="1.41" height="2.82" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '305.275px 190.6px'}} id="elpccxj08jo8q" className="animable" />
        <rect x="311.83" y="189.19" width="1.41" height="2.82" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '312.535px 190.6px'}} id="elrsv89jjaq6l" className="animable" />
        <rect x="305.76" y="184.63" width="6.27" height="11.02" rx="3.13" style={{fill: 'rgb(38, 50, 56)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '308.895px 190.14px'}} id="elg05v6zdoco" className="animable" />
        <polygon points="300 220.99 294.67 221.66 296.33 242.99 297.83 242.99 298.33 226.82 302 226.53 300 220.99" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '298.335px 231.99px'}} id="elhwfsr4bgz1w" className="animable" />
        <polygon points="317 220.99 322.33 221.66 320.67 242.99 319.17 242.99 318.67 226.82 315 226.53 317 220.99" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '318.665px 231.99px'}} id="ellb3sf34nlm" className="animable" />
        <polygon points="297.5 196.53 320 196.53 314.75 226.53 302 226.53 297.5 196.53" style={{fill: 'rgb(144, 202, 249)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '308.75px 211.53px'}} id="elhxgz0sqpja8" className="animable" />
        <polygon points="297.5 196.53 291.38 207.53 289.13 203.28 287.75 203.66 290.63 212.28 298.5 204.66 297.5 196.53" style={{fill: 'rgb(144, 202, 249)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '293.125px 204.405px'}} id="elrw7cicrxbr" className="animable" />
        <polygon points="320 196.53 326.52 196.69 328.89 191.19 330.26 191.57 327.39 200.19 318.5 204.78 320 196.53" style={{fill: 'rgb(144, 202, 249)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '324.38px 197.985px'}} id="elyrvbjzqpcke" className="animable" />
        <polygon points="289.13 203.28 290 202.66 290 201.28 289.73 201.22 289.3 201.76 288.25 199.91 286.96 200.91 287.75 203.66 289.13 203.28" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '288.48px 201.785px'}} id="elhmzwgbg70na" className="animable" />
        <polygon points="328.89 191.19 328.01 190.57 328.01 189.19 328.28 189.14 328.71 189.67 329.76 187.82 331.06 188.82 330.26 191.57 328.89 191.19" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '329.535px 189.695px'}} id="elhhwv5jmdrig" className="animable" />
        <rect x="307.08" y="227.66" width="2.71" height="11.17" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '308.435px 233.245px'}} id="elbjlfjvx4qx" className="animable" />
        <path d="M323.69,244.16l-13.25-2.75v-8.92h-4v8.92l-13.25,2.75v1.66l14.25-2.21V246h2v-2.38l14.25,2.21Z" style={{fill: 'rgb(38, 50, 56)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '308.44px 239.245px'}} id="el6hcuayjyo53" className="animable" />
        <polygon points="316.85 228.32 299.98 228.32 298 202.66 318.83 202.66 316.85 228.32" style={{fill: 'rgb(38, 50, 56)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '308.415px 215.49px'}} id="el5rp7v95m7ff" className="animable" />
        <rect x="305.5" y="195.16" width="6.83" height="1.33" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '308.915px 195.825px'}} id="ely7tnkz2ek2j" className="animable" />
      </g>
      <g id="freepik--character-2--inject-2" className="animable" style={{transformOrigin: '238.58px 215.315px'}}>
        <rect x="237.08" y="191.91" width="3.49" height="5.97" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '238.825px 194.895px'}} id="elrm719mebf6" className="animable" />
        <rect x="234.4" y="189.19" width="1.41" height="2.82" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '235.105px 190.6px'}} id="elx8vwozkzsqb" className="animable" />
        <rect x="241.66" y="189.19" width="1.41" height="2.82" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '242.365px 190.6px'}} id="elj1mty4my71c" className="animable" />
        <path d="M238.72,195.65h0a3.13,3.13,0,0,1-3.13-3.13v-4.75a3.14,3.14,0,0,1,3.13-3.14h0a3.15,3.15,0,0,1,3.14,3.14v4.75A3.14,3.14,0,0,1,238.72,195.65Z" style={{fill: 'rgb(38, 50, 56)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '238.725px 190.14px'}} id="el1u6k0pxwhoy" className="animable" />
        <polygon points="229.83 220.99 224.5 221.66 226.17 242.99 227.67 242.99 228.17 226.82 231.83 226.53 229.83 220.99" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '228.165px 231.99px'}} id="eljx2nruc9hwa" className="animable" />
        <polygon points="246.83 220.99 252.17 221.66 250.5 242.99 249 242.99 248.5 226.82 244.83 226.53 246.83 220.99" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '248.5px 231.99px'}} id="elxw7qif0ue6k" className="animable" />
        <polygon points="227.33 196.53 249.83 196.53 244.58 226.53 231.83 226.53 227.33 196.53" style={{fill: 'rgb(144, 202, 249)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '238.58px 211.53px'}} id="el5xwty624q39" className="animable" />
        <polygon points="227.33 196.53 221.21 207.53 218.96 203.28 217.58 203.66 220.46 212.28 228.33 204.66 227.33 196.53" style={{fill: 'rgb(144, 202, 249)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '222.955px 204.405px'}} id="elp1zbqf9wsx" className="animable" />
        <polygon points="249.83 196.53 255.96 207.53 258.21 203.28 259.58 203.66 256.71 212.28 248.33 204.78 249.83 196.53" style={{fill: 'rgb(144, 202, 249)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '253.955px 204.405px'}} id="el82feupgorso" className="animable" />
        <polygon points="218.96 203.28 219.83 202.66 219.83 201.28 219.56 201.22 219.14 201.76 218.08 199.91 216.79 200.91 217.58 203.66 218.96 203.28" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '218.31px 201.785px'}} id="elfa38297rii" className="animable" />
        <polygon points="258.21 203.28 257.33 202.66 257.33 201.28 257.6 201.22 258.03 201.76 259.08 199.91 260.37 200.91 259.58 203.66 258.21 203.28" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '258.85px 201.785px'}} id="elava0rfkxtzh" className="animable" />
        <rect x="236.92" y="227.66" width="2.71" height="11.17" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '238.275px 233.245px'}} id="elx6k0h2jlv8t" className="animable" />
        <path d="M253.52,244.16l-13.25-2.75v-8.92h-4v8.92L223,244.16v1.66l14.25-2.21V246h2v-2.38l14.25,2.21Z" style={{fill: 'rgb(38, 50, 56)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '238.26px 239.245px'}} id="ele95kugpml" className="animable" />
        <polygon points="246.69 228.32 229.81 228.32 227.83 202.66 248.67 202.66 246.69 228.32" style={{fill: 'rgb(38, 50, 56)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '238.25px 215.49px'}} id="elm6a12xgfd" className="animable" />
        <rect x="235.33" y="195.16" width="6.83" height="1.33" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '238.745px 195.825px'}} id="elk85j97wf72" className="animable" />
      </g>
      <g id="freepik--character-1--inject-2" className="animable" style={{transformOrigin: '176.41px 199.164px'}}>
        <path d="M168.38,162v-5.11a3.31,3.31,0,0,1,2.43-3.21,9.91,9.91,0,0,1,1.88-.29,4.12,4.12,0,0,1,4.3,3c.5,1.32,0,8.44,0,8.44a1.86,1.86,0,0,1-2.32.5V167h-6.12Z" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '172.796px 160.188px'}} id="elblksu2marbs" className="animable" />
        <path d="M177,156.36a4.13,4.13,0,0,0-4.3-3,9.91,9.91,0,0,0-1.88.29,3.31,3.31,0,0,0-2.43,3.21V162l.07,2.14a4.27,4.27,0,0,0,2.08.67,5.15,5.15,0,0,0,4.47-1.65l-1.32-.83-.66-3,1.49-.33.49,2.15h.83l-.16-2.48h1Z" style={{fill: 'rgb(38, 50, 56)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '172.695px 159.099px'}} id="elxpp54wzcagj" className="animable" />
        <rect x="166.72" y="166.62" width="9.27" height="2.32" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '171.355px 167.78px'}} id="elbwimyioi0lh" className="animable" />
        <polygon points="158.78 183.84 163.58 177.22 166.06 190.47 178.81 190.47 180.63 178.22 188.58 180.53 194.71 164.97 193.05 164.47 187.26 174.57 180.8 168.44 162.75 168.44 154.97 182.85 161.92 195.6 163.41 195.1 158.78 183.84" style={{fill: 'rgb(144, 202, 249)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '174.84px 180.035px'}} id="el6qtugsk87kc" className="animable" />
        <polygon points="166.06 190.47 165.56 197.09 160.76 242.96 162.59 242.96 172.19 200.74 173.35 200.74 183.94 242.3 186.1 242.3 179.47 195.77 178.81 190.47 166.06 190.47" style={{fill: 'rgb(38, 50, 56)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '173.43px 216.715px'}} id="el5nimyart81e" className="animable" />
        <polygon points="193.05 164.47 195.37 158.18 196.2 158.34 195.87 160 197.85 160.99 197.03 163.97 196.03 163.97 194.71 164.97 193.05 164.47" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '195.45px 161.575px'}} id="elrbfr9tzqw9e" className="animable" />
        <polygon points="163.41 195.1 165.07 198.77 163.58 199.59 161.92 195.6 163.41 195.1" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '163.495px 197.345px'}} id="elbv1ss2fl4pc" className="animable" />
        <polygon points="186.1 242.3 189.94 244.98 184.1 244.98 183.94 242.3 186.1 242.3" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '186.94px 243.64px'}} id="elprujvzxajg8" className="animable" />
        <polygon points="160.67 242.3 156.84 244.98 162.67 244.98 162.83 242.3 160.67 242.3" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.75px', transformOrigin: '159.835px 243.64px'}} id="elpqa6uadxm1i" className="animable" />
      </g>
      <g id="freepik--device-2--inject-2" className="animable" style={{transformOrigin: '336.17px 314.565px'}}>
        <path d="M280.61,302h68a0,0,0,0,1,0,0V363a0,0,0,0,1,0,0h-68a1.62,1.62,0,0,1-1.62-1.62v-57.8A1.62,1.62,0,0,1,280.61,302Z" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', transformOrigin: '313.8px 332.5px'}} id="eln4aiwxsdjb" className="animable" />
        <rect x="280.82" y="301.98" width="70.13" height="61.05" style={{fill: 'rgb(38, 50, 56)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', transformOrigin: '315.885px 332.505px'}} id="elawd3lqgq666" className="animable" />
        <rect x="286.22" y="306.82" width="60.9" height="51.38" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', transformOrigin: '316.67px 332.51px'}} id="el4s7cvieoo9j" className="animable" />
        <g id="el0w7dcv1xsukq">
          <rect x="290.86" y="310.73" width="17.42" height="43.56" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', transformOrigin: '299.57px 332.51px', transform: 'rotate(180deg)'}} className="animable" />
        </g>
        <g id="elkiweevblafq">
          <rect x="290.86" y="314.12" width="51.63" height="40.17" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', transformOrigin: '316.675px 334.205px', transform: 'rotate(180deg)'}} className="animable" />
        </g>
        <g id="elaoozouzovy4">
          <rect x="293.54" y="316.21" width="46.26" height="35.99" style={{fill: 'rgb(144, 202, 249)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', transformOrigin: '316.67px 334.205px', transform: 'rotate(180deg)'}} className="animable" />
        </g>
        <line x1="295.56" y1="327.96" x2="304.18" y2="327.96" style={{fill: 'none', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', transformOrigin: '299.87px 327.96px'}} id="elndtztttjo5k" className="animable" />
        <line x1="295.56" y1="330.58" x2="304.18" y2="330.58" style={{fill: 'none', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', transformOrigin: '299.87px 330.58px'}} id="elw2mskfme77r" className="animable" />
        <line x1="295.56" y1="333.19" x2="304.18" y2="333.19" style={{fill: 'none', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', transformOrigin: '299.87px 333.19px'}} id="el0p70a6oi9m2" className="animable" />
        <line x1="295.56" y1="337.63" x2="333.43" y2="337.63" style={{fill: 'none', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', transformOrigin: '314.495px 337.63px'}} id="el4zkxuoqg5v6" className="animable" />
        <line x1="295.56" y1="341.02" x2="333.43" y2="341.02" style={{fill: 'none', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', transformOrigin: '314.495px 341.02px'}} id="el7m321upptqr" className="animable" />
        <line x1="295.56" y1="344.42" x2="333.43" y2="344.42" style={{fill: 'none', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', transformOrigin: '314.495px 344.42px'}} id="elmq8m6nuh07g" className="animable" />
        <polygon points="280.82 363.04 321.68 376.57 393.35 376.57 350.95 363.04 280.82 363.04" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', transformOrigin: '337.085px 369.805px'}} id="elqlf5blv1m1o" className="animable" />
        <polygon points="331.85 368.25 325.94 366.36 323.04 366.33 328.93 368.23 331.85 368.25" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '327.445px 367.29px'}} id="el9swf445s2o" className="animable" />
        <polygon points="337.62 368.3 331.69 366.42 328.82 366.39 334.75 368.27 337.62 368.3" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '333.22px 367.345px'}} id="elbn34xadtrlf" className="animable" />
        <polygon points="357.87 370.3 360.64 370.31 354.47 368.42 351.71 368.4 357.87 370.3" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '356.175px 369.355px'}} id="el4budt4okmiy" className="animable" />
        <polygon points="328.93 368.23 323.04 366.33 320.12 366.31 325.99 368.21 328.93 368.23" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '324.525px 367.27px'}} id="eltg2obu68wkr" className="animable" />
        <polygon points="323.03 368.19 317.17 366.28 314.21 366.25 320.05 368.17 323.03 368.19" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '318.62px 367.22px'}} id="el3xik9kkd8y4" className="animable" />
        <polygon points="348.93 368.38 342.94 366.52 340.15 366.5 346.13 368.36 348.93 368.38" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '344.54px 367.44px'}} id="elmwvxfx21i4a" className="animable" />
        <polygon points="351.71 368.4 345.7 366.55 342.94 366.52 348.93 368.38 351.71 368.4" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '347.325px 367.46px'}} id="elazk7soh8m3" className="animable" />
        <polygon points="320.05 368.17 314.21 366.25 311.22 366.22 317.05 368.14 320.05 368.17" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '315.635px 367.195px'}} id="el9srnl0h8btp" className="animable" />
        <polygon points="340.48 368.32 334.53 366.44 331.69 366.42 337.62 368.3 340.48 368.32" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '336.085px 367.37px'}} id="elzem7j4skcnr" className="animable" />
        <polygon points="354.47 368.42 348.44 366.58 345.7 366.55 351.71 368.4 354.47 368.42" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '350.085px 367.485px'}} id="el0mdzvdnzb15o" className="animable" />
        <polygon points="346.13 368.36 340.15 366.5 337.35 366.47 343.32 368.34 346.13 368.36" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '341.74px 367.415px'}} id="el2ykar0xwy7e" className="animable" />
        <polygon points="343.32 368.34 337.35 366.47 334.53 366.44 340.48 368.32 343.32 368.34" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '338.925px 367.39px'}} id="elw1l90wu499" className="animable" />
        <polygon points="334.75 368.27 328.82 366.39 325.94 366.36 331.85 368.25 334.75 368.27" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '330.345px 367.315px'}} id="elybbbwaabi5r" className="animable" />
        <polygon points="314.02 368.12 310.97 368.1 316.92 370.09 319.98 370.11 314.02 368.12" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '315.475px 369.105px'}} id="el985jziv06gu" className="animable" />
        <polygon points="308.21 366.19 305.19 366.16 310.97 368.1 314.02 368.12 308.21 366.19" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '309.605px 367.14px'}} id="el5942qtvm0g8" className="animable" />
        <polygon points="317.05 368.14 311.22 366.22 308.21 366.19 314.02 368.12 317.05 368.14" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '312.63px 367.165px'}} id="elhj9wddojrf" className="animable" />
        <polygon points="323.01 372.13 329.28 374.23 332.38 374.23 326.1 372.14 323.01 372.13" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '327.695px 373.18px'}} id="elpxaled84hk" className="animable" />
        <polygon points="319.98 370.11 316.92 370.09 323.01 372.13 326.1 372.14 319.98 370.11" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '321.51px 371.115px'}} id="eldptt66jgn89" className="animable" />
        <polygon points="326.1 372.14 332.38 374.23 335.46 374.23 329.16 372.15 326.1 372.14" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '330.78px 373.185px'}} id="el7bcixfy03y" className="animable" />
        <polygon points="325.99 368.21 320.12 366.31 317.17 366.28 323.03 368.19 325.99 368.21" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '321.58px 367.245px'}} id="ellh0owk7qs1p" className="animable" />
        <polygon points="332.02 370.17 325.99 368.21 323.03 368.19 329.04 370.15 332.02 370.17" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '327.525px 369.18px'}} id="elfsh5j40w6b6" className="animable" />
        <polygon points="329.04 370.15 323.03 368.19 320.05 368.17 326.04 370.14 329.04 370.15" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '324.545px 369.16px'}} id="el6t439bbu8ot" className="animable" />
        <polygon points="334.98 370.18 328.93 368.23 325.99 368.21 332.02 370.17 334.98 370.18" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '330.485px 369.195px'}} id="elyffxgwo8x1k" className="animable" />
        <polygon points="317.05 368.14 314.02 368.12 319.98 370.11 323.02 370.12 317.05 368.14" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '318.52px 369.12px'}} id="elmfiouf3ar5k" className="animable" />
        <polygon points="364.19 372.24 366.97 372.25 360.64 370.31 357.87 370.3 364.19 372.24" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '362.42px 371.275px'}} id="elbx2h14xdnwr" className="animable" />
        <polygon points="326.04 370.14 320.05 368.17 317.05 368.14 323.02 370.12 326.04 370.14" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '321.545px 369.14px'}} id="elg00wqkqbuk" className="animable" />
        <polygon points="319.98 370.11 326.1 372.14 329.16 372.15 323.02 370.12 319.98 370.11" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '324.57px 371.13px'}} id="ell6b00v50op" className="animable" />
        <polygon points="352.27 370.27 346.13 368.36 343.32 368.34 349.44 370.25 352.27 370.27" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '347.795px 369.305px'}} id="elfskg7kaxcya" className="animable" />
        <polygon points="349.44 370.25 343.32 368.34 340.48 368.32 346.58 370.24 349.44 370.25" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '344.96px 369.285px'}} id="elyw88w1cxodl" className="animable" />
        <polygon points="357.87 370.3 351.71 368.4 348.93 368.38 355.08 370.28 357.87 370.3" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '353.4px 369.34px'}} id="el84s7b42lb3b" className="animable" />
        <polygon points="340.82 370.21 334.75 368.27 331.85 368.25 337.91 370.19 340.82 370.21" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '336.335px 369.23px'}} id="elqu71m52rb3k" className="animable" />
        <polygon points="346.58 370.24 340.48 368.32 337.62 368.3 343.71 370.22 346.58 370.24" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '342.1px 369.27px'}} id="eldzrx5the9k" className="animable" />
        <polygon points="343.71 370.22 337.62 368.3 334.75 368.27 340.82 370.21 343.71 370.22" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '339.23px 369.245px'}} id="elsqiy7k0t28" className="animable" />
        <polygon points="337.91 370.19 331.85 368.25 328.93 368.23 334.98 370.18 337.91 370.19" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '333.42px 369.21px'}} id="elfgkdweyj277" className="animable" />
        <polygon points="346.58 370.24 352.85 372.21 355.71 372.22 349.44 370.25 346.58 370.24" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '351.145px 371.23px'}} id="el49tv7g927gd" className="animable" />
        <polygon points="349.44 370.25 355.71 372.22 358.56 372.23 352.27 370.27 349.44 370.25" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '354px 371.24px'}} id="elxlxgb6ywf8f" className="animable" />
        <polygon points="323.02 370.12 329.16 372.15 332.2 372.16 326.04 370.14 323.02 370.12" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '327.61px 371.14px'}} id="elq62n43b2f8" className="animable" />
        <polygon points="352.27 370.27 358.56 372.23 361.38 372.23 355.08 370.28 352.27 370.27" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '356.825px 371.25px'}} id="eljfkgr43df1j" className="animable" />
        <polygon points="340.82 370.21 347.06 372.19 349.96 372.2 343.71 370.22 340.82 370.21" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '345.39px 371.205px'}} id="el9kmpnb71px8" className="animable" />
        <polygon points="355.08 370.28 361.38 372.23 364.19 372.24 357.87 370.3 355.08 370.28" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '359.635px 371.26px'}} id="el9klb7hct6bs" className="animable" />
        <polygon points="343.71 370.22 349.96 372.2 352.85 372.21 346.58 370.24 343.71 370.22" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '348.28px 371.215px'}} id="elmbhytkkhrx" className="animable" />
        <polygon points="329.04 370.15 335.21 372.17 338.21 372.17 332.02 370.17 329.04 370.15" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '333.625px 371.16px'}} id="el8ohccdu2d6" className="animable" />
        <polygon points="326.04 370.14 332.2 372.16 335.21 372.17 329.04 370.15 326.04 370.14" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '330.625px 371.155px'}} id="elxg7chb75wt" className="animable" />
        <polygon points="337.91 370.19 344.13 372.19 347.06 372.19 340.82 370.21 337.91 370.19" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '342.485px 371.19px'}} id="eljs4k7hzmfo" className="animable" />
        <polygon points="334.98 370.18 341.18 372.18 344.13 372.19 337.91 370.19 334.98 370.18" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '339.555px 371.185px'}} id="ellpjiec4rfd" className="animable" />
        <polygon points="332.02 370.17 338.21 372.17 341.18 372.18 334.98 370.18 332.02 370.17" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '336.6px 371.175px'}} id="el8hlkxpztrw6" className="animable" />
        <polygon points="355.08 370.28 348.93 368.38 346.13 368.36 352.27 370.27 355.08 370.28" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '350.605px 369.32px'}} id="el9dhqau2kvth" className="animable" />
        <polygon points="308.21 366.19 302.02 364.13 299.02 364.1 305.19 366.16 308.21 366.19" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '303.615px 365.145px'}} id="elvx6gb5k20f" className="animable" />
        <polygon points="305.19 366.16 302.37 366.13 308.14 368.08 310.97 368.1 305.19 366.16" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '306.67px 367.115px'}} id="elovr2yjkycpm" className="animable" />
        <polygon points="299.02 364.1 296.22 364.06 302.37 366.13 305.19 366.16 299.02 364.1" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '300.705px 365.11px'}} id="elm75qx02zlf" className="animable" />
        <polygon points="310.97 368.1 308.14 368.08 314.06 370.08 316.92 370.09 310.97 368.1" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '312.53px 369.085px'}} id="elojs0joe3qy" className="animable" />
        <polygon points="316.92 370.09 314.06 370.08 320.15 372.13 323.01 372.13 316.92 370.09" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '318.535px 371.105px'}} id="ele3wywibzig" className="animable" />
        <polygon points="323.01 372.13 320.15 372.13 326.4 374.23 329.28 374.23 323.01 372.13" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '324.715px 373.18px'}} id="eltjfjv92j60o" className="animable" />
        <polygon points="357.2 368.44 363.39 370.32 365.81 370.33 359.61 368.46 357.2 368.44" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '361.505px 369.385px'}} id="eldmfpupc9il" className="animable" />
        <polygon points="369.73 372.25 372.16 372.26 365.81 370.33 363.39 370.32 369.73 372.25" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '367.775px 371.29px'}} id="elc7w2dn32ops" className="animable" />
        <polygon points="376.24 374.23 378.68 374.23 372.16 372.26 369.73 372.25 376.24 374.23" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '374.205px 373.24px'}} id="elr61m11it1sp" className="animable" />
        <polygon points="331.69 366.42 325.35 364.41 322.5 364.38 328.82 366.39 331.69 366.42" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '327.095px 365.4px'}} id="ellj0pa2aoi3" className="animable" />
        <polygon points="357.2 368.44 359.61 368.46 353.57 366.63 351.17 366.6 357.2 368.44" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '355.39px 367.53px'}} id="el3gsyvppyf44" className="animable" />
        <polygon points="366.97 372.25 369.73 372.25 363.39 370.32 360.64 370.31 366.97 372.25" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '365.185px 371.28px'}} id="eleyblgqnz1vv" className="animable" />
        <polygon points="311.22 366.22 305.01 364.17 302.02 364.13 308.21 366.19 311.22 366.22" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '306.62px 365.175px'}} id="elpyrpc53vpmr" className="animable" />
        <polygon points="348.44 366.58 354.47 368.42 357.2 368.44 351.17 366.6 348.44 366.58" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '352.82px 367.51px'}} id="elqgtrgkqt6kn" className="animable" />
        <polygon points="353.57 366.63 347.11 364.67 344.73 364.64 351.17 366.6 353.57 366.63" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '349.15px 365.635px'}} id="elho2mgkt54tp" className="animable" />
        <polygon points="351.17 366.6 344.73 364.64 342.01 364.61 348.44 366.58 351.17 366.6" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '346.59px 365.605px'}} id="ele8l7e7k2ac6" className="animable" />
        <polygon points="358.56 372.23 365.01 374.23 367.85 374.23 361.38 372.23 358.56 372.23" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '363.205px 373.23px'}} id="el0mqvo6ms8kfm" className="animable" />
        <polygon points="373.46 374.23 376.24 374.23 369.73 372.25 366.97 372.25 373.46 374.23" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '371.605px 373.24px'}} id="elehng485gnw5" className="animable" />
        <polygon points="348.44 366.58 342.01 364.61 339.28 364.58 345.7 366.55 348.44 366.58" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '343.86px 365.58px'}} id="elgebahw0ngzb" className="animable" />
        <polygon points="364.19 372.24 370.67 374.23 373.46 374.23 366.97 372.25 364.19 372.24" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '368.825px 373.235px'}} id="elu8d1ueoaplt" className="animable" />
        <polygon points="361.38 372.23 367.85 374.23 370.67 374.23 364.19 372.24 361.38 372.23" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '366.025px 373.23px'}} id="elccdhgutt5tp" className="animable" />
        <polygon points="360.64 370.31 363.39 370.32 357.2 368.44 354.47 368.42 360.64 370.31" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '358.93px 369.37px'}} id="elw37mb3p40c" className="animable" />
        <polygon points="323.04 366.33 316.76 364.31 313.85 364.27 320.12 366.31 323.04 366.33" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '318.445px 365.3px'}} id="eltxbr1evugg" className="animable" />
        <polygon points="345.7 366.55 339.28 364.58 336.54 364.54 342.94 366.52 345.7 366.55" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '341.12px 365.545px'}} id="ely06yl7hmei8" className="animable" />
        <polygon points="320.12 366.31 313.85 364.27 310.93 364.24 317.17 366.28 320.12 366.31" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '315.525px 365.275px'}} id="elkg2cgv0b61" className="animable" />
        <polygon points="328.82 366.39 322.5 364.38 319.64 364.34 325.94 366.36 328.82 366.39" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '324.23px 365.365px'}} id="elouczvimaeva" className="animable" />
        <polygon points="317.17 366.28 310.93 364.24 307.98 364.2 314.21 366.25 317.17 366.28" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '312.575px 365.24px'}} id="elzopsofgq5dq" className="animable" />
        <polygon points="314.21 366.25 307.98 364.2 305.01 364.17 311.22 366.22 314.21 366.25" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '309.61px 365.21px'}} id="elunex0am9vw" className="animable" />
        <polygon points="325.94 366.36 319.64 364.34 316.76 364.31 323.04 366.33 325.94 366.36" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '321.35px 365.335px'}} id="el09n9xhjjlq0n" className="animable" />
        <polygon points="342.94 366.52 336.54 364.54 333.77 364.51 340.15 366.5 342.94 366.52" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '338.355px 365.515px'}} id="elcxu9mw394t" className="animable" />
        <polygon points="340.15 366.5 333.77 364.51 330.98 364.48 337.35 366.47 340.15 366.5" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '335.565px 365.49px'}} id="el2q8tvakcfy4" className="animable" />
        <polygon points="334.53 366.44 328.18 364.44 325.35 364.41 331.69 366.42 334.53 366.44" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '329.94px 365.425px'}} id="elxndn15hz24" className="animable" />
        <polygon points="358.56 372.23 355.71 372.22 352.85 372.21 349.96 372.2 347.06 372.19 344.13 372.19 341.18 372.18 338.21 372.17 335.21 372.17 332.2 372.16 329.16 372.15 335.46 374.23 338.52 374.23 341.55 374.23 344.56 374.23 347.55 374.23 350.51 374.23 353.45 374.23 356.38 374.23 359.27 374.23 362.15 374.23 365.01 374.23 358.56 372.23" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '347.085px 373.19px'}} id="elrkstg5ehny" className="animable" />
        <polygon points="337.35 366.47 330.98 364.48 328.18 364.44 334.53 366.44 337.35 366.47" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '332.765px 365.455px'}} id="elhoau60oxc7v" className="animable" />
        <polygon points="280.82 363.04 280.82 365.08 321.66 379.18 321.68 376.57 280.82 363.04" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', transformOrigin: '301.25px 371.11px'}} id="el417il1sxn0c" className="animable" />
        <rect x="321.68" y="376.57" width="71.67" height="2.61" style={{fill: 'rgb(38, 50, 56)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', transformOrigin: '357.515px 377.875px'}} id="elz28dk670uxc" className="animable" />
        <polygon points="301.91 279.41 317.57 303.3 333.24 279.41 326.68 279.41 326.68 249.95 308.46 249.95 308.46 279.41 301.91 279.41" style={{fill: 'rgb(144, 202, 249)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', transformOrigin: '317.575px 276.625px'}} id="elczyrzfitmka" className="animable" />
      </g>
      <g id="freepik--device-1--inject-2" className="animable" style={{transformOrigin: '172.7px 310.9px'}}>
        <g id="el5zkqtqqtfsv">
          <path d="M161.88,302h68a0,0,0,0,1,0,0V363a0,0,0,0,1,0,0h-68a1.62,1.62,0,0,1-1.62-1.62v-57.8A1.62,1.62,0,0,1,161.88,302Z" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', transformOrigin: '195.07px 332.5px', transform: 'rotate(180deg)'}} className="animable" />
        </g>
        <g id="eloipeg0wk7b9">
          <rect x="157.92" y="301.98" width="70.13" height="61.05" style={{fill: 'rgb(38, 50, 56)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', transformOrigin: '192.985px 332.505px', transform: 'rotate(180deg)'}} className="animable" />
        </g>
        <g id="elhvq95jsfd5f">
          <rect x="161.75" y="306.82" width="60.9" height="51.38" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', transformOrigin: '192.2px 332.51px', transform: 'rotate(180deg)'}} className="animable" />
        </g>
        <g id="eliurpn8dqfz">
          <rect x="166.38" y="310.73" width="17.42" height="43.56" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', transformOrigin: '175.09px 332.51px', transform: 'rotate(180deg)'}} className="animable" />
        </g>
        <g id="elebjchxs72n7">
          <rect x="166.38" y="314.12" width="51.63" height="40.17" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', transformOrigin: '192.195px 334.205px', transform: 'rotate(180deg)'}} className="animable" />
        </g>
        <g id="eldktxfvupdts">
          <rect x="169.07" y="316.21" width="46.26" height="35.99" style={{fill: 'rgb(144, 202, 249)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', transformOrigin: '192.2px 334.205px', transform: 'rotate(180deg)'}} className="animable" />
        </g>
        <line x1="171.09" y1="327.96" x2="179.7" y2="327.96" style={{fill: 'none', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', transformOrigin: '175.395px 327.96px'}} id="el21hkmn5echu" className="animable" />
        <line x1="171.09" y1="330.58" x2="179.7" y2="330.58" style={{fill: 'none', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', transformOrigin: '175.395px 330.58px'}} id="el8peusfgds6v" className="animable" />
        <line x1="171.09" y1="333.19" x2="179.7" y2="333.19" style={{fill: 'none', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', transformOrigin: '175.395px 333.19px'}} id="eldnz6011d0jr" className="animable" />
        <line x1="171.09" y1="337.63" x2="208.95" y2="337.63" style={{fill: 'none', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', transformOrigin: '190.02px 337.63px'}} id="el6fyoyfc8ix" className="animable" />
        <line x1="171.09" y1="341.02" x2="208.95" y2="341.02" style={{fill: 'none', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', transformOrigin: '190.02px 341.02px'}} id="el56swc3ovff5" className="animable" />
        <line x1="171.09" y1="344.42" x2="208.95" y2="344.42" style={{fill: 'none', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', transformOrigin: '190.02px 344.42px'}} id="elxz3ruacnb5" className="animable" />
        <polygon points="228.04 363.04 187.19 376.57 115.52 376.57 157.92 363.04 228.04 363.04" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', transformOrigin: '171.78px 369.805px'}} id="elze2e6bs8lb" className="animable" />
        <polygon points="177.02 368.25 182.92 366.36 185.83 366.33 179.94 368.23 177.02 368.25" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '181.425px 367.29px'}} id="eljzarbmd1wk" className="animable" />
        <polygon points="171.24 368.3 177.18 366.42 180.04 366.39 174.12 368.27 171.24 368.3" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '175.64px 367.345px'}} id="elcutykksip9" className="animable" />
        <polygon points="151 370.3 148.23 370.31 154.4 368.42 157.16 368.4 151 370.3" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '152.695px 369.355px'}} id="elnabd2t3rzmp" className="animable" />
        <polygon points="179.94 368.23 185.83 366.33 188.75 366.31 182.87 368.21 179.94 368.23" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '184.345px 367.27px'}} id="elommi2hddx1l" className="animable" />
        <polygon points="185.84 368.19 191.69 366.28 194.66 366.25 188.82 368.17 185.84 368.19" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '190.25px 367.22px'}} id="ell71poukoje" className="animable" />
        <polygon points="159.94 368.38 165.93 366.52 168.72 366.5 162.73 368.36 159.94 368.38" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '164.33px 367.44px'}} id="el5apv784q86" className="animable" />
        <polygon points="157.16 368.4 163.17 366.55 165.93 366.52 159.94 368.38 157.16 368.4" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '161.545px 367.46px'}} id="elxp9kc5871r" className="animable" />
        <polygon points="188.82 368.17 194.66 366.25 197.64 366.22 191.82 368.14 188.82 368.17" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '193.23px 367.195px'}} id="elhfc1qck063" className="animable" />
        <polygon points="168.39 368.32 174.34 366.44 177.18 366.42 171.24 368.3 168.39 368.32" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '172.785px 367.37px'}} id="el9l24quefihw" className="animable" />
        <polygon points="154.4 368.42 160.42 366.58 163.17 366.55 157.16 368.4 154.4 368.42" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '158.785px 367.485px'}} id="el7as0bi7f5w8" className="animable" />
        <polygon points="162.73 368.36 168.72 366.5 171.52 366.47 165.55 368.34 162.73 368.36" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '167.125px 367.415px'}} id="elam6ca9l7ged" className="animable" />
        <polygon points="165.55 368.34 171.52 366.47 174.34 366.44 168.39 368.32 165.55 368.34" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '169.945px 367.39px'}} id="el9eb0yguju64" className="animable" />
        <polygon points="174.12 368.27 180.04 366.39 182.92 366.36 177.02 368.25 174.12 368.27" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '178.52px 367.315px'}} id="el3ji6sh1bkho" className="animable" />
        <polygon points="194.84 368.12 197.89 368.1 191.95 370.09 188.89 370.11 194.84 368.12" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '193.39px 369.105px'}} id="elo6okw0gwa9h" className="animable" />
        <polygon points="200.65 366.19 203.68 366.16 197.89 368.1 194.84 368.12 200.65 366.19" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '199.26px 367.14px'}} id="elguo2qlminao" className="animable" />
        <polygon points="191.82 368.14 197.64 366.22 200.65 366.19 194.84 368.12 191.82 368.14" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '196.235px 367.165px'}} id="elchvxbdt16ta" className="animable" />
        <polygon points="185.85 372.13 179.59 374.23 176.48 374.23 182.77 372.14 185.85 372.13" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '181.165px 373.18px'}} id="elzw0147oeej" className="animable" />
        <polygon points="188.89 370.11 191.95 370.09 185.85 372.13 182.77 372.14 188.89 370.11" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '187.36px 371.115px'}} id="elxjq17xy7h4l" className="animable" />
        <polygon points="182.77 372.14 176.48 374.23 173.41 374.23 179.71 372.15 182.77 372.14" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '178.09px 373.185px'}} id="elarxn4lbjval" className="animable" />
        <polygon points="182.87 368.21 188.75 366.31 191.69 366.28 185.84 368.19 182.87 368.21" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '187.28px 367.245px'}} id="elmeli9vj4xes" className="animable" />
        <polygon points="176.85 370.17 182.87 368.21 185.84 368.19 179.82 370.15 176.85 370.17" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '181.345px 369.18px'}} id="eljvp919f6scs" className="animable" />
        <polygon points="179.82 370.15 185.84 368.19 188.82 368.17 182.82 370.14 179.82 370.15" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '184.32px 369.16px'}} id="eljkrrpcfutd" className="animable" />
        <polygon points="173.89 370.18 179.94 368.23 182.87 368.21 176.85 370.17 173.89 370.18" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '178.38px 369.195px'}} id="el5rjxtnmuzze" className="animable" />
        <polygon points="191.82 368.14 194.84 368.12 188.89 370.11 185.84 370.12 191.82 368.14" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '190.34px 369.12px'}} id="eltbvwwqkzer9" className="animable" />
        <polygon points="144.68 372.24 141.9 372.25 148.23 370.31 151 370.3 144.68 372.24" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '146.45px 371.275px'}} id="eljw07p448zil" className="animable" />
        <polygon points="182.82 370.14 188.82 368.17 191.82 368.14 185.84 370.12 182.82 370.14" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '187.32px 369.14px'}} id="elk7ze2ss961" className="animable" />
        <polygon points="188.89 370.11 182.77 372.14 179.71 372.15 185.84 370.12 188.89 370.11" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '184.3px 371.13px'}} id="el4m6jc7o9qwd" className="animable" />
        <polygon points="156.6 370.27 162.73 368.36 165.55 368.34 159.43 370.25 156.6 370.27" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '161.075px 369.305px'}} id="elpb6sxlob6er" className="animable" />
        <polygon points="159.43 370.25 165.55 368.34 168.39 368.32 162.28 370.24 159.43 370.25" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '163.91px 369.285px'}} id="el0ymv5725d72" className="animable" />
        <polygon points="151 370.3 157.16 368.4 159.94 368.38 153.79 370.28 151 370.3" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '155.47px 369.34px'}} id="el6ttl11p78fi" className="animable" />
        <polygon points="168.04 370.21 174.12 368.27 177.02 368.25 170.96 370.19 168.04 370.21" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '172.53px 369.23px'}} id="ellmqhfqlott" className="animable" />
        <polygon points="162.28 370.24 168.39 368.32 171.24 368.3 165.15 370.22 162.28 370.24" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '166.76px 369.27px'}} id="el93p76voy7rt" className="animable" />
        <polygon points="165.15 370.22 171.24 368.3 174.12 368.27 168.04 370.21 165.15 370.22" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '169.635px 369.245px'}} id="elvs17idok99" className="animable" />
        <polygon points="170.96 370.19 177.02 368.25 179.94 368.23 173.89 370.18 170.96 370.19" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '175.45px 369.21px'}} id="elr1vq84rc2nb" className="animable" />
        <polygon points="162.28 370.24 156.02 372.21 153.15 372.22 159.43 370.25 162.28 370.24" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '157.715px 371.23px'}} id="elni0s9u000c" className="animable" />
        <polygon points="159.43 370.25 153.15 372.22 150.31 372.23 156.6 370.27 159.43 370.25" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '154.87px 371.24px'}} id="ellbr00pauxl" className="animable" />
        <polygon points="185.84 370.12 179.71 372.15 176.67 372.16 182.82 370.14 185.84 370.12" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '181.255px 371.14px'}} id="elelnvn8aowni" className="animable" />
        <polygon points="156.6 370.27 150.31 372.23 147.49 372.23 153.79 370.28 156.6 370.27" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '152.045px 371.25px'}} id="elvh0h70o01fs" className="animable" />
        <polygon points="168.04 370.21 161.81 372.19 158.9 372.2 165.15 370.22 168.04 370.21" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '163.47px 371.205px'}} id="el3414p1hrfgp" className="animable" />
        <polygon points="153.79 370.28 147.49 372.23 144.68 372.24 151 370.3 153.79 370.28" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '149.235px 371.26px'}} id="el92o40cpwnij" className="animable" />
        <polygon points="165.15 370.22 158.9 372.2 156.02 372.21 162.28 370.24 165.15 370.22" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '160.585px 371.215px'}} id="elsgv9egmt6zq" className="animable" />
        <polygon points="179.82 370.15 173.65 372.17 170.66 372.17 176.85 370.17 179.82 370.15" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '175.24px 371.16px'}} id="el42h2tmoe0sd" className="animable" />
        <polygon points="182.82 370.14 176.67 372.16 173.65 372.17 179.82 370.15 182.82 370.14" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '178.235px 371.155px'}} id="elm1r0wm6s1a" className="animable" />
        <polygon points="170.96 370.19 164.74 372.19 161.81 372.19 168.04 370.21 170.96 370.19" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '166.385px 371.19px'}} id="elub9gzyeoa08" className="animable" />
        <polygon points="173.89 370.18 167.69 372.18 164.74 372.19 170.96 370.19 173.89 370.18" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '169.315px 371.185px'}} id="elaa907gbynj" className="animable" />
        <polygon points="176.85 370.17 170.66 372.17 167.69 372.18 173.89 370.18 176.85 370.17" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '172.27px 371.175px'}} id="elou9utkgsqd" className="animable" />
        <polygon points="153.79 370.28 159.94 368.38 162.73 368.36 156.6 370.27 153.79 370.28" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '158.26px 369.32px'}} id="elj44axxop4f8" className="animable" />
        <polygon points="200.65 366.19 206.84 364.13 209.85 364.1 203.68 366.16 200.65 366.19" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '205.25px 365.145px'}} id="elroiu8it5nb8" className="animable" />
        <polygon points="203.68 366.16 206.5 366.13 200.73 368.08 197.89 368.1 203.68 366.16" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '202.195px 367.115px'}} id="el7p2ead5kvpv" className="animable" />
        <polygon points="209.85 364.1 212.65 364.06 206.5 366.13 203.68 366.16 209.85 364.1" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '208.165px 365.11px'}} id="elglqhtx4xlhb" className="animable" />
        <polygon points="197.89 368.1 200.73 368.08 194.8 370.08 191.95 370.09 197.89 368.1" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '196.34px 369.085px'}} id="elgqponabnb7r" className="animable" />
        <polygon points="191.95 370.09 194.8 370.08 188.72 372.13 185.85 372.13 191.95 370.09" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '190.325px 371.105px'}} id="elgkeantru9h" className="animable" />
        <polygon points="185.85 372.13 188.72 372.13 182.47 374.23 179.59 374.23 185.85 372.13" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '184.155px 373.18px'}} id="el3imsxb9qk46" className="animable" />
        <polygon points="151.66 368.44 145.48 370.32 143.06 370.33 149.25 368.46 151.66 368.44" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '147.36px 369.385px'}} id="elwfzcs3veo2r" className="animable" />
        <polygon points="139.13 372.25 136.71 372.26 143.06 370.33 145.48 370.32 139.13 372.25" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '141.095px 371.29px'}} id="elvaqflsjpxuk" className="animable" />
        <polygon points="132.63 374.23 130.19 374.23 136.71 372.26 139.13 372.25 132.63 374.23" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '134.66px 373.24px'}} id="el8k0wnidyrsq" className="animable" />
        <polygon points="177.18 366.42 183.52 364.41 186.36 364.38 180.04 366.39 177.18 366.42" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '181.77px 365.4px'}} id="elp6bl9d1itx" className="animable" />
        <polygon points="151.66 368.44 149.25 368.46 155.3 366.63 157.7 366.6 151.66 368.44" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '153.475px 367.53px'}} id="elp54hakt7mf" className="animable" />
        <polygon points="141.9 372.25 139.13 372.25 145.48 370.32 148.23 370.31 141.9 372.25" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '143.68px 371.28px'}} id="els8r6z6mdorm" className="animable" />
        <polygon points="197.64 366.22 203.85 364.17 206.84 364.13 200.65 366.19 197.64 366.22" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '202.24px 365.175px'}} id="el7t7ipm8qvf6" className="animable" />
        <polygon points="160.42 366.58 154.4 368.42 151.66 368.44 157.7 366.6 160.42 366.58" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '156.04px 367.51px'}} id="el67wv9jmwnck" className="animable" />
        <polygon points="155.3 366.63 161.76 364.67 164.14 364.64 157.7 366.6 155.3 366.63" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '159.72px 365.635px'}} id="elimybvjqze6a" className="animable" />
        <polygon points="157.7 366.6 164.14 364.64 166.85 364.61 160.42 366.58 157.7 366.6" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '162.275px 365.605px'}} id="eltuyver4fd1" className="animable" />
        <polygon points="150.31 372.23 143.85 374.23 141.02 374.23 147.49 372.23 150.31 372.23" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '145.665px 373.23px'}} id="ela3112fhw5kg" className="animable" />
        <polygon points="135.41 374.23 132.63 374.23 139.13 372.25 141.9 372.25 135.41 374.23" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '137.265px 373.24px'}} id="elp2mdew6b9l" className="animable" />
        <polygon points="160.42 366.58 166.85 364.61 169.58 364.58 163.17 366.55 160.42 366.58" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '165px 365.58px'}} id="elsjrjrgnz7ah" className="animable" />
        <polygon points="144.68 372.24 138.2 374.23 135.41 374.23 141.9 372.25 144.68 372.24" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '140.045px 373.235px'}} id="el3xq6ue4nv3z" className="animable" />
        <polygon points="147.49 372.23 141.02 374.23 138.2 374.23 144.68 372.24 147.49 372.23" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '142.845px 373.23px'}} id="el7721g58xq7l" className="animable" />
        <polygon points="148.23 370.31 145.48 370.32 151.66 368.44 154.4 368.42 148.23 370.31" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '149.94px 369.37px'}} id="eli5um31f642" className="animable" />
        <polygon points="185.83 366.33 192.11 364.31 195.02 364.27 188.75 366.31 185.83 366.33" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '190.425px 365.3px'}} id="elr99nu6jg85h" className="animable" />
        <polygon points="163.17 366.55 169.58 364.58 172.33 364.54 165.93 366.52 163.17 366.55" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '167.75px 365.545px'}} id="elbbn2o8hl9k" className="animable" />
        <polygon points="188.75 366.31 195.02 364.27 197.94 364.24 191.69 366.28 188.75 366.31" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '193.345px 365.275px'}} id="elbs493ietrxs" className="animable" />
        <polygon points="180.04 366.39 186.36 364.38 189.23 364.34 182.92 366.36 180.04 366.39" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '184.635px 365.365px'}} id="el6rbiphcvaqj" className="animable" />
        <polygon points="191.69 366.28 197.94 364.24 200.89 364.2 194.66 366.25 191.69 366.28" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '196.29px 365.24px'}} id="elvh59ar27i" className="animable" />
        <polygon points="194.66 366.25 200.89 364.2 203.85 364.17 197.64 366.22 194.66 366.25" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '199.255px 365.21px'}} id="elh9vf4iqe4uo" className="animable" />
        <polygon points="182.92 366.36 189.23 364.34 192.11 364.31 185.83 366.33 182.92 366.36" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '187.515px 365.335px'}} id="elwvve5qlrsnk" className="animable" />
        <polygon points="165.93 366.52 172.33 364.54 175.1 364.51 168.72 366.5 165.93 366.52" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '170.515px 365.515px'}} id="elau79qqop2dk" className="animable" />
        <polygon points="168.72 366.5 175.1 364.51 177.89 364.48 171.52 366.47 168.72 366.5" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '173.305px 365.49px'}} id="ellzyfvbb31d" className="animable" />
        <polygon points="174.34 366.44 180.69 364.44 183.52 364.41 177.18 366.42 174.34 366.44" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '178.93px 365.425px'}} id="elv79jinpb29h" className="animable" />
        <polygon points="150.31 372.23 153.15 372.22 156.02 372.21 158.9 372.2 161.81 372.19 164.74 372.19 167.69 372.18 170.66 372.17 173.65 372.17 176.67 372.16 179.71 372.15 173.41 374.23 170.35 374.23 167.32 374.23 164.31 374.23 161.32 374.23 158.36 374.23 155.41 374.23 152.49 374.23 149.59 374.23 146.71 374.23 143.85 374.23 150.31 372.23" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '161.78px 373.19px'}} id="elp6o43ejgnw" className="animable" />
        <polygon points="171.52 366.47 177.89 364.48 180.69 364.44 174.34 366.44 171.52 366.47" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '0.25px', transformOrigin: '176.105px 365.455px'}} id="elkqnkek10i8" className="animable" />
        <polygon points="228.04 363.04 228.04 365.08 187.2 379.18 187.19 376.57 228.04 363.04" style={{fill: 'rgb(255, 255, 255)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', transformOrigin: '207.615px 371.11px'}} id="el88kv518s7my" className="animable" />
        <g id="elkr9yymamnfs">
          <rect x="115.52" y="376.57" width="71.67" height="2.61" style={{fill: 'rgb(38, 50, 56)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', transformOrigin: '151.355px 377.875px', transform: 'rotate(180deg)'}} className="animable" />
        </g>
        <polygon points="211.46 266.51 195.8 242.62 180.13 266.51 186.69 266.51 186.69 302.64 204.91 302.64 204.91 266.51 211.46 266.51" style={{fill: 'rgb(144, 202, 249)', stroke: 'rgb(38, 50, 56)', strokeLinecap: 'round', strokeLinejoin: 'round', transformOrigin: '195.795px 272.63px'}} id="elxgnlbzcj2ig" className="animable" />
      </g>
      <defs>
        <filter id="active" height="200%">
          <feMorphology in="SourceAlpha" result="DILATED" operator="dilate" radius={2} />
          <feFlood floodColor="#32DFEC" floodOpacity={1} result="PINK" />
          <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
          <feMerge>
            <feMergeNode in="OUTLINE" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="hover" height="200%">
          <feMorphology in="SourceAlpha" result="DILATED" operator="dilate" radius={2} />
          <feFlood floodColor="#ff0000" floodOpacity="0.5" result="PINK" />
          <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE" />
          <feMerge>
            <feMergeNode in="OUTLINE" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
          <feColorMatrix type="matrix" values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 " />
        </filter>
      </defs>
    </svg>
    </div>
  );
}
